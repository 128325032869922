import { IonCol, IonIcon, IonItem, IonRow, IonText } from "@ionic/react"
import ProfileImage from "./ProfileImage"
import { location, star, walk } from 'ionicons/icons';
import { generateRandomString } from "../services/util";
import { useEffect } from "react";

interface UserCardProps {
  user: any
}

const UserCard: React.FC<UserCardProps> = ({user}) => {

  const getRankRows = () => {
    if (user && user.ranks && Object.keys(user.ranks).map !== undefined){
      return Object.keys(user.ranks).map((rank: string, index: number, array: any[]) => {
        return (
          <IonRow key={generateRandomString()}>
            <IonIcon style={{ marginRight: "5px" }} color="main-text" icon={star} />
            <IonText>{(array.length > 1) ? rank:null} Rank: {user.ranks[rank]}</IonText>
          </IonRow>
        );
      })
    }
   return null;
  }
  
  return (
    <IonItem routerLink={`/user/view/${user.id}`} detail={true}>
			<ProfileImage 
        icon_type="none"
        size="75px" 
        filename={user?.preference?.image ?? ""}
        style={{marginRight: '15px'}}
      />
      <IonCol>
        <IonRow>
          <IonText style={{fontSize: '18px', paddingBottom: '5px'}}>{user.full_name}</IonText>
        </IonRow>
        {getRankRows()}
        <IonRow className="ion-align-items-center">
          <IonCol style={{maxWidth: '22px'}}>
            <IonIcon style={{ marginRight: "5px", fontSize: '18px' }} color="main-text" icon={location} />
          </IonCol>
          <IonCol>
            <IonRow>
              <IonText style={{fontSize: '15px'}}>{user?.preference?.location}</IonText>
            </IonRow>
            {(user && user.preference && user.preference.distance_miles !== null) ?
            (<IonRow style={{paddingTop: '1px'}}>
              <IonText style={{fontSize: '11px'}}>{user.preference.distance_miles?.toFixed(1)} miles away</IonText>
            </IonRow>):null}
          </IonCol>
        </IonRow>
      </IonCol>
    </IonItem>
	)
}

export default UserCard;