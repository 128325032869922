import styled from 'styled-components';
import {
	IonPage,
	IonContent,
	IonFab,
	IonFabButton,
	IonIcon,
} from "@ionic/react";
import './SplashPage.css';
import Link from "../../components/Link";
import Links from "../../components/Links";
import AuthNav from 'components/AuthNav';
import TitleCardSimple from 'components/TitleCardSimple';
import usePromptPWA from 'hooks/usePromptPWA';
import { add } from 'ionicons/icons';

const SplashPage = () => {

	const {showPrompt, prompt} = usePromptPWA();

	return (
		<IonPage>
			<IonContent
				className="ion-padding splash-content"
				fullscreen
			>
				<PageColumn>
					<div style={{padding: "50px 20px 20px"}}>
						<TitleCardSimple showSubtitle={true} />
					</div>
					<div>
						<Links>
							<Link path="/login" color="accent-two" textColor="basic-grey">
								Sign In
							</Link>
							<Link path="/register" color="light-grey" textColor="basic-grey">
								Sign Up
							</Link>
						</Links>
						<AuthNav />
					</div>
					{showPrompt &&
						<IonFab vertical="top" horizontal="end" slot="fixed">
							<IonFabButton
								size="small"
								color={'accent-two'}
								onClick={() => prompt()}
							>
								<IonIcon icon={add} />
							</IonFabButton>
						</IonFab>
					}
				</PageColumn>
			</IonContent>
		</IonPage>
	);
};

export default SplashPage

const PageColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;

