import { IonButton, IonContent, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonToggle, LocationHistory } from "@ionic/react"
import Challenge from "components/Challenge";
import CommonHeader from "components/CommonHeader"
import PillRadios from "components/PillRadios";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import { Challenges } from "services/api";
import { joinISODateAndTime } from "services/time";
import styled from "styled-components";
import useAsyncEffect from "use-async-effect";

const ModifyChallengePage: React.FC<RouteComponentProps> = () => {

  let { id } = useParams<Params>();
	const {token} = useAuth();
  const [sport, setSport] = useState<string>("");
  const [availableSports, setAvailableSports] = useState<Sport[]>([])
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [challengeLocation, setChallengeLocation] = useState<string>("");
  const [ranked, setRanked] = useState<boolean>(false);
  const [toast] = useToast("Hello");

  useAsyncEffect(async () => {
    if (id){
      const result = await Challenges.view(token ?? "", id);
      if (result.success){
        const { message, sport, challenge_start, location, ranked, recipients } = result.payload;
        const availableSports = recipients.map((recipient: User) => {
          return recipient.sports;
        }).flat();
        setAvailableSports(availableSports);
        setMessage(message);
        setSport(sport.name);
        setChallengeLocation(location);
        setDate(challenge_start);
        setTime(challenge_start);
        setRanked(ranked);
      }
    }
  }, [token])

	return (
		<IonPage>
			<CommonHeader title={`Modify Challenge`}/>
			<IonContent>
			  <IonList>
          <IonItem className="form-input" data-name="sport">
            <IonLabel>Sport</IonLabel>
            <PillRadios
              options={availableSports.map((sport: Sport) => sport.name)}
              value={sport}
              onChange={(selectedSport:string) => setSport(selectedSport)}
            />
          </IonItem>
          <TimeRow>
            <IonItem className="form-input" data-name="date">
              <IonLabel position="stacked">Start Date</IonLabel>
              <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="time">
                <IonLabel position="stacked">Start Time</IonLabel>
                <IonDatetime
                  name="time"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked">Location</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={challengeLocation}
              onIonChange={(e) => {
                  setChallengeLocation(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem>
              <IonLabel>Ranked</IonLabel>
              <IonToggle checked={ranked} onIonChange={e => setRanked(e.detail.checked)} />
          </IonItem>
          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked">Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={6}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (id){
                if (!e.currentTarget) return;
                e.preventDefault();

                if (!sport){
                  toast({message: 'Select a sport'}, true);
                  return;
                }
  
                if (!challengeLocation){
                  toast({message: 'Add a location'}, true);
                  return;
                }
  
                if (!date || !time){
                  toast({message: 'Please set a date and time'}, true);
                  return;
                }

                let args = {
                  sport,
                  location: challengeLocation,
                  date: joinISODateAndTime(date, time),
                  message,
                  ranked: (ranked) ? 1:0
                }
                const response = Challenges.edit(token ?? "", args, id);
                if (response){
                  response.then(function(data){
                    if (data.success){
                      toast({message: "Challenge Updated"});
                    } else {
                      toast({message: "Error Updating Challenge"}, true);
                    }
                  });
                }
              } else {
                toast({message: "Error Updating Challenge"}, true);
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
			</IonContent>
		</IonPage>
	)
}

export default ModifyChallengePage;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;