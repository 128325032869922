import {
	IonPage,
	IonContent
} from "@ionic/react";
import useAsyncEffect from "use-async-effect";
import { Users } from "../../services/api";
import TitleCardSimple from "components/TitleCardSimple";
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";


SwiperCore.use([]);

const AboutPage = () => {

	useAsyncEffect(async () => {
		let result = await Users.getInfo();
	},[])

	return (
		<IonPage>
			<IonContent className="ion-padding basic-bg basic-bg-pad content-width">
				<div style={{ padding: "50px 0 30px"}}>
					<TitleCardSimple />
				</div>

				<Swiper
					slidesPerView={1}
				>
          <SwiperSlide>
						<div>Find more challenging players across a wide variety of sports & games.</div>
					</SwiperSlide>
          <SwiperSlide>
						<div>Issue challenges and invite them to play with you competitively.</div>
					</SwiperSlide>
          <SwiperSlide>
						<div>Record your Win/Loss and watch your power ranking rise or fall.</div>
					</SwiperSlide>
					<SwiperSlide>
						<div>Rate other players to let others know who's a good sport</div>
					</SwiperSlide>
        </Swiper>
			</IonContent>
		</IonPage>
	);
};

export default AboutPage