import { IonCard, IonCardContent, IonRow } from "@ionic/react"
import styled from "styled-components";
import useUser from "../hooks/useUser";
import { generateRandomString, getLinearGradient } from "../services/util";
import Card from "./Card";
import ChallengePrompt from "./ChallengePrompt";
import PodiumIcon from "./PodiumIcon";

const Stats: React.FC = ({}) => {

	const { user } = useUser();

	const getRatingRow = (sport: any) => {
		return (
			<IonRow key={generateRandomString()}>
				<div style={{ flex: 1 }}>{sport.name}</div>
				<StatsRow>
					{sport._joinData.rank}
				</StatsRow>
				<StatsRow>
					{sport._joinData.numWins}
				</StatsRow>
				<StatsRow>
					{sport._joinData.numPlayed}
				</StatsRow>
			</IonRow>
		)
	}

	const getSportsRatings = () => {
		let sports: any = [];
		if (user && user.sports && user.sports.length > 0){
			sports = user.sports;
		};
		let sports_arr = [];
		if (sports.map !== undefined){
			sports_arr = sports
				/*.filter((sport_obj: any) => sport_obj._joinData.active)*/
				.map((sport_obj: any) => getRatingRow(sport_obj))
		};
		if (sports_arr.length > 0){
			return (
				<div>
					<IonRow>
						<IonRow 
							style={{ flex: 1, fontSize: "30px", marginBottom: '10px' }} 
							className="ion-align-items-center"
						>
							<PodiumIcon style={{marginRight: '7px'}}/>
							<Title>Stats</Title>
						</IonRow>
						<StatsHeaderRow>
							Rank
						</StatsHeaderRow>
						<StatsHeaderRow>
							Wins
						</StatsHeaderRow>
						<StatsHeaderRow>
							Played
						</StatsHeaderRow>
					</IonRow>
					<div>
						{sports_arr}
					</div>
			</div>
			);
		} else {
			let msg = "Complete challenges with other\nusers to view your stats";
			return (
				<div>
					<IonRow 
						style={{ flex: 1, fontSize: "30px" }} 
						className="ion-align-items-center"
					>
						<PodiumIcon style={{marginRight: '7px'}}/>
						Stats
					</IonRow>
					<ChallengePrompt msg={msg} />
				</div>
			);
		}
	}

	return (
		<Card>
			{ getSportsRatings() }
		</Card>
	)

	return (
		<IonCard
			style={{"--background": getLinearGradient('accent-two'), marginBottom: '0'}}
		>
			<IonCardContent>
			{ getSportsRatings() }
			</IonCardContent>
		</IonCard>
	)
}

export default Stats;

const Title = styled.div`
	font-family: var(--heading-font);
`;

const StatsRow = styled.div`
	width: 60px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: end;
`;

const StatsHeaderRow = styled(StatsRow)`
	margin-bottom: 14px;
`;

