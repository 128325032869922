import { IonAvatar, IonIcon, IonImg, IonSpinner } from "@ionic/react";
import { settings, image } from "ionicons/icons";
import { memo, useRef, useState } from "react";
import styled from "styled-components";
import { CONFIG } from "../constants";
import { getLinearGradient } from "../services/util";
import { Users } from '../services/api'
import useAuth from "../hooks/useAuth";
import useWindowSize from "../hooks/useWindowSize";
import { Link } from "react-router-dom";
import useToast from "../hooks/useToast";

interface ProfileImageProps {
  filename?: string
  fallback?: string
  size?: string
  minSize?: string
  maxSize?: string
  icon_type?: IconType
  icon_offset?: string
  icon_color?: string
  paddingString?: string
  style?: Object
  isLeader?: boolean
  spinner_type?: SpinnerType
  onFileChange?: (e: any, callback: (prop: any) => void) => Promise<void>
	onImageLoad?: (e: any) => void
  refresh?: () => void
}

const ProfileImage: React.FC<ProfileImageProps> = ({ 
  minSize, 
  maxSize,
  size,
  icon_color, 
  icon_type,
  fallback = CONFIG.DEFAULT_PROFILE_IMAGE,
  filename,
  icon_offset = "0",
  paddingString = "var(--horizontal-gutter)",
  style = {},
  spinner_type = 'crescent' as SpinnerType,
  refresh
}) => {
  console.log('received filename', filename);
  const maxLoadAttempts = 1;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLIonImgElement>(null);
  const {windowWidth, windowHeight} = useWindowSize();
  const {token} = useAuth();
  const [toast] = useToast();

  const timeoutRef = useRef<number | null>(null);

  const onFileChange = async (e: any) => {
    setLoading(true);
    const image = e.target.files[0];
    if (image){
      let formData = new FormData();
      formData.append("image_file", image, image.name);
      let response = await Users.updateProfileImage(token ?? "", formData);
      if (response && response.data){
        let data = response.data;
        if (data.success){
          if (refresh){
            refresh();
          }
          if (setImageSrc(addPathToFilename(image.name))){
            toast({message: "Successfully updated image"});
          } else {
            setLoading(false);
          }
        } else {
          data.message.forEach((error: string) => console.error(error));
          toast({message: "Problem updating image"}, true);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const triggerFileInput = () => {
    if (inputRef.current != null){
      inputRef.current.click();
    }
  }


  // parse size strings to something usable
  const getSize = (str: string | undefined, half = false) => {
    if (!str) return '20px';

    // handle pixels 
    let pixelSplit = str.split('px');
    if (pixelSplit.length > 1){
      if (half){
        str = (parseFloat(pixelSplit[0]) / 2) + pixelSplit[1];
      }
      return str;
    }

    // handle percent
    let percentSplit = str.split('%'); 
    if (percentSplit.length > 1){
      let percent = parseFloat(percentSplit[0]) / 100;
      if (half) percent = percent / 2;
      if (windowWidth){
        let pixels = percent * windowWidth;
        return pixels + 'px';
      }
    }

    // handle vw
    let vwSplit = str.split('vw'); 
    if (vwSplit.length > 1){
      let percent = parseFloat(vwSplit[0]) / 100;
      if (half) percent = percent / 2;
      if (windowWidth){
        let pixels = percent * windowWidth;
        return pixels + 'px';
      }
    }

    // handle vh
    let vhSplit = str.split('vh'); 
    if (vwSplit.length > 1){
      let percent = parseFloat(vhSplit[0]) / 100;
      if (half) percent = percent / 2;
      if (windowHeight){
        let pixels = percent * windowHeight;
        return pixels + 'px';
      }
    }
    return str;
  }

  const setImageSrc = (src: string) => {
    if (imageRef.current){
      console.log('setting image src', src);
      imageRef.current.src = src;
      return true;
    } else {
      return false;
    }
  }

  const addPathToFilename = (filename: string = CONFIG.DEFAULT_PROFILE_IMAGE) => {
    if (filename){
      return CONFIG.BASE_URL + '/img/profileImages/' + filename;
    } else {
      return CONFIG.BASE_URL + '/img/profileImages/' + CONFIG.DEFAULT_PROFILE_IMAGE;
    }
  }

  return (
    <Avatar
      style={style}
      minSize={getSize(minSize ?? size)}
      maxSize={getSize(maxSize ?? size)}
      size={getSize(size)}
      className={(loading) ? "loading":"Loaded"}
      padding={paddingString}
    >
      <IonImg
        src={(() => {
          const full = addPathToFilename(filename);
          console.log('full', full);
          return full;
        })()}
        ref={imageRef}
        style={{
          visibility: (loading) ? 'hidden':'visible',
          position: (loading) ? 'absolute':'static'
        }}
        onIonError={(e) => {
          console.log('IMAGE ERROR', e);
          setImageSrc(addPathToFilename(CONFIG.DEFAULT_PROFILE_IMAGE));
          setLoading(false);
        }}
        onIonImgWillLoad={() => {
          console.log('will load', filename);
          if (filename !== null){
            setImageSrc(addPathToFilename(CONFIG.DEFAULT_PROFILE_IMAGE));
            setLoading(true);
          }
        }}
        onIonImgDidLoad={() => {
          setLoading(false);
        }}
      />
      {(loading) ? (
      <IonSpinner
        name={spinner_type}
        style={{
          width: "100%",
          height: getSize(size, true),
        }} 
      />):null}
      {(icon_type === 'edit' && !loading) ? (
        <IconLinkWrapper color={icon_color} offset={icon_offset} paddingTop={7}>
          <Link to={`/user/profile/edit`}>
            <IonIcon color="main" icon={settings} />
          </Link>
        </IconLinkWrapper>
      ):null}
      {(icon_type === 'change' && !loading) ? (
        <IconLinkWrapper as="div" color={icon_color} offset={icon_offset} paddingTop={1}>
          <input type="file" hidden ref={inputRef} onChange={onFileChange} />
          <IonIcon color="main" icon={image} onClick={triggerFileInput} />
        </IconLinkWrapper>
      ):null}
    </Avatar>
  );
}

export default memo(ProfileImage);


const Avatar = styled(IonAvatar)<{
  minSize: string,
  maxSize: string,
  size: string,
  padding: string
}>`
  width: ${props => `clamp(${props.minSize}, ${props.size}, ${props.maxSize})`};
  height: ${props => `clamp(${props.minSize}, ${props.size}, ${props.maxSize})`};
  padding: ${props => props.padding};
  position: relative;
`;

const IconLinkWrapper = styled.div<{
  color?: string
  offset?: string
  paddingTop?: number
}>`
  position: absolute;
  right: 20%;
  bottom: ${props => props.offset};
  background: ${props => getLinearGradient(props.color || 'light')};
  width: clamp(25px, 12vw, 45px);
  height: clamp(25px, 12vw, 45px);
  font-size: clamp(20px, 10vw, 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => `${props.paddingTop}px`} 0 0 0;
  border-radius: 50px;
`;