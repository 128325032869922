import { ReactComponentOrElement } from "@ionic/react/dist/types/hooks/useOverlay";
import React from "react";
import styled from "styled-components";
import './PodiumIcon.css';

const Outer = styled.div<{size: number}>`
  border-radius: 40px;
  width: ${(props: any) => `${props.size}px`};
  height: ${(props: any) => `${props.size}px`};
  background: linear-gradient(180deg, #F2A170 0%, rgba(242, 161, 112, 0.4) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  width: 63%;
  height: 63%;
`;

const PodiumIcon: React.FC<{size?: number, style?: React.CSSProperties}> = (props, { size = 30 }) => {
  return (
    <Outer size={size} {...props}>
      <Inner className="inner"></Inner>
    </Outer>
  )
}

export default PodiumIcon;