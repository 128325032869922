import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { CONFIG } from "../constants";
import { makePostRequest } from './fetching';

let currentUserSubject: any = new BehaviorSubject({});
const userFromStorage = localStorage.getItem('currentUser');
if (userFromStorage && userFromStorage !== "undefined"){
	currentUserSubject = new BehaviorSubject(JSON.parse(userFromStorage));
}

export const AuthenticationService = {
	user: currentUserSubject,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue () { return currentUserSubject.value },

	login: async (email: string, password: string) => {
		const formData = new FormData();
		formData.append('email', email);
		formData.append('password', password);
		const endpoint = CONFIG.API_ENDPOINT + '/users/login';
		const controller = new AbortController();
		const signal = controller.signal;
		const response = await makePostRequest(endpoint, "", formData, signal, false);
		if (response.success){
			localStorage.setItem('currentUser', JSON.stringify(response.payload));
			currentUserSubject.next(response.payload);
			
		}
		AuthenticationService.loginCancel = controller.abort;
		return response;
	},
	loginCancel: () => {},

	register: async (args: RegisterProps) => {
		const formData = new FormData();
		formData.append('last_name', args.last_name);
		formData.append('first_name', args.first_name);
		formData.append('email', args.email);
		formData.append('password', args.password);
		formData.append('confirm', args.confirm);
		const endpoint = CONFIG.API_ENDPOINT + '/users/register';
		const controller = new AbortController();
		const signal = controller.signal;
		const response = await makePostRequest(endpoint, "", formData, signal, false);
		console.log('inner', { response });
		if (response.success){
			localStorage.setItem('currentUser', JSON.stringify(response.payload));
			currentUserSubject.next(response.payload);
		}
		AuthenticationService.registerCancel = controller.abort;
		return response;
	},
	registerCancel: () => {},

	forgot: async (email: string) => {
		const formData = new FormData();
		formData.append('email', email);
		const endpoint = CONFIG.API_ENDPOINT + '/users/forgot-password';
		const controller = new AbortController();
		const signal = controller.signal;
		const response = await makePostRequest(endpoint, "", formData, signal, false);
		AuthenticationService.forgotCancel = controller.abort;
		return response;
	},
	forgotCancel: () => {},

	logout: () => {
		localStorage.removeItem('currentUser');
		currentUserSubject.next(null);
	}
};