import { IonCol, IonIcon, IonItem, IonRow, IonText } from "@ionic/react";
import useAuth from "hooks/useAuth";
import { ellipse, ellipseOutline, trophy } from "ionicons/icons";
import { timeAgo } from "services/time";
import { truncate } from "services/util";
import styled from "styled-components";

const TournamentDisplay: React.FC<{ 
	tournament: Tournament
	user_id: string | null
}> = ({ tournament, user_id }) => {

	const {userID} = useAuth();
	
	const getUser = () => {
		return tournament.users.find((user: User) => {
			return user.id === userID;
		});
	}

	const hasChanged = () => {
		if (user_id === null) return false;
		if (tournament.user_id === user_id){
			// is organizer
			return tournament?.hasChangedSinceLastViewed;
		} else {
			let found_user = tournament.users.find(user => user.id === user_id);
			let hasChanged = found_user?._joinData?.hasChangedSinceLastViewed
			return hasChanged;
		}
	}

	const isPending = () => {
		return getStatus() === "Pending";
	}

	const getStatus = () => {
		const user = getUser();
		return user?._joinData?.status;
	}

	return (
		<Tournament routerLink={`/tournament/view/${tournament.id}`} lines="none">
			<Dot
				size={7}
				color={(hasChanged()) ? "danger":"main"}
			/>
			{/* <IonIcon icon={trophy} style={{color: (isPending()) ? "var(--ion-color-accent-two":"unset"}} slot="start" /> */}
			<Col>
					<Title>{truncate(tournament.title, 20)}</Title>
					<Time>Starts {timeAgo(tournament.tournament_start).agoString}</Time>
			</Col>
			<Status>
				{tournament.status}
			</Status>
		</Tournament>
	);
}

export default TournamentDisplay;

const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	font-size: 16px;
`;

const Time = styled.div`
	padding-top: 2px;
	font-size: 12px;
`;

const Status = styled.div`
	font-size: 12px;
	background: var(--ion-color-accent-two);
	color: var(--ion-color-constant-dark);
	box-shadow: 0 0 0 1px #333;
	border-radius: 100px;
	padding: 3px 10px 1px;
	font-size: 11px;
	text-align: center;
	width: min-content;
	align-self: start;
	position: absolute;
	top: 4px;
	right: 4px;
`;

const Tournament = styled(IonItem)`
	padding: 10px 0 0;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	--background: var(--ion-background-color-shade);
	--border-radius: var(--radius);
	--inner-padding-top: 15px;
	--inner-padding-bottom: 10px;
	--inner-padding-left: 0;
	--inner-padding-right: 0;
`;

const Dot = styled.div<{ size: number, color: string | undefined }>`
	height: ${(props: any) => `${props.size}px`};
	width: ${(props: any) => `${props.size}px`};
	background: var(--ion-color-${props => props.color});
	border-radius: 50px;
	border: 2px solid var(--ion-color-${props => props.color});
	margin: 5px 15px 5px 0;
`;
