import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import useAuth from "hooks/useAuth";
import { useState, ReactNode, useEffect, memo } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import ChallengesList from "./ChallengesList";
import PageTitle from "./PageTitle";

interface ChallengeTabsProps extends RouteComponentProps {
	pending: Challenge[]
	upcoming: Challenge[]
	past: Challenge[]
	user_id?: string
	refreshChallenges: () => void
	onChallengeTypeChange?: (type: ChallengeListType) => void 
}

interface ChallengeListProps {title: string, challenges: Challenge[]}

const ChallengesTabs: React.FC<ChallengeTabsProps> = memo((props) => {

	const {userID} = useAuth();
	const [challengeListType, setChallengeListType] = useState<ChallengeListType>('pending');
	const [challengeList, setChallengeList] = useState<ReactNode>();
	const [challengeListRef, setChallengeListRef] = useState<ReactNode>();

	const user_id = props.user_id ?? userID

	const getChallengeList = ({title, challenges}: ChallengeListProps): ReactNode => {
		if (!user_id) return null;
		return <ChallengesList 
			title={title} 
			challenges={challenges} 
			contextual_user_id={user_id}
			refreshChallenges={props.refreshChallenges}
		/>
	}

	useEffect(() => {
		let payload: ChallengeListProps = {title: "", challenges: []};
		if (challengeListType === 'pending'){
			payload.title = "Pending";
			payload.challenges = props.pending;
		} else if (challengeListType === 'upcoming'){
			payload.title = "Upcoming";
			payload.challenges = props.upcoming;
		} else if (challengeListType === 'past') {
			payload.title = "Past";
			payload.challenges = props.past;
		}
		let newNodes = getChallengeList(payload);
		if (JSON.stringify(newNodes) !== JSON.stringify(challengeListRef)){
			setChallengeList(newNodes);
			setChallengeListRef(newNodes);
		}
	}, [challengeListType, props.pending])

	return (
		<div>
			<div style={{ margin: "0 var(--horizontal-gutter)" }}>
				<IonSegment
					onIonChange={e => {
						const nextChallengeType = e.detail.value as ChallengeListType;
						setChallengeListType(nextChallengeType);
						if (props.onChallengeTypeChange){
							props.onChallengeTypeChange(nextChallengeType);
						}
					}}
					value={challengeListType}
					mode="ios"
				>
					<IonSegmentButton value="pending">
						<IonLabel>Pending</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value="upcoming">
						<IonLabel>Upcoming</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value="past">
						<IonLabel>Past</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</div>
			{ challengeList }
		</div>
	)
});

export default withRouter(ChallengesTabs);