import { IonContent, IonPage } from '@ionic/react';
import WaveTabs from '../components/WaveTabs';
import PageTitle from '../components/PageTitle';
import MatchSearch from '../components/MatchSearch';
import TournamentSearch from '../components/TournamentSearch';
import { RouteComponentProps } from 'react-router';

const SearchPage: React.FC<RouteComponentProps> = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <WaveTabs 
          leftTitle="Match" 
          rightTitle="Tournament" 
          leftComponent={<MatchSearch />} 
          rightComponent={<TournamentSearch />}
          color='accent-two'
        >
          <PageTitle color="main">Search</PageTitle>
        </WaveTabs>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
