import {
	IonPage,
	IonContent,
	IonList,
	IonItem,
	IonButton,
	IonLabel,
	IonInput,
	IonTextarea,
	IonRow,
	IonIcon,
	IonToggle,
	IonCol,
	useIonAlert,
} from "@ionic/react";
import CommonHeader from "../components/CommonHeader";
import SportsDropdown from "../components/SportsDropdown";
import { useContext, useEffect, useState } from "react";
import GenderRadio from "../components/GenderRadio";
import useUser from "../hooks/useUser";
import ProfileImage from "../components/ProfileImage";
import { Users } from "../services/api";
import useAuth from "../hooks/useAuth";
import { convertGenderStringToGenderObject, validateAnyZip, validateEmail } from "../services/util";
import useDynamicInputs from "../hooks/useDynamicInputs";
import AddressDropdown from "../components/AddressDropdown";
import NewAddressInputs from "../components/NewAddressInputs";
import useToast from "../hooks/useToast";
import useDarkTheme from "../hooks/useDarkTheme";
import { moon, sunny } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";
import { AuthenticationService } from "services/auth";
import { UserContext } from "State/UserContext";


const ProfileEditPage: React.FC<RouteComponentProps> = (props) => {
	const {token, userID} = useAuth();
	const {genders, sports} = useDynamicInputs();
	const {userRefresh} = useUser();
	const {state: { user, userLoading }} = useContext(UserContext);
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [age, setAge] = useState<number | null>(18);
	const [gender, setGender] = useState<string>("");
	const [bio, setBio] = useState<string>("");
	const [selectedSports, setSelectedSports] = useState<string[]>([]);
	const [primaryAddress, setPrimaryAddress] = useState<string>();
	const [lineOne, setLineOne] = useState<string>("");
  const [lineTwo, setLineTwo] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
	const [toast] = useToast("Hello");
	const {prefersDark} = useDarkTheme();
	
	useEffect(() => {
		setFirstName((user) ? user.first_name:"");
		setLastName((user) ? user.last_name:"");
		setEmail((user) ? user.email:"");
		setAge((user && user.preference && user.preference.age) ? user.preference?.age:null);
		setGender((user && user.preference && user.preference.gender) ? user?.preference?.gender?.name:"");
		setBio((user && user.preference && user.preference.bio) ? user.preference?.bio:"");
		setSelectedSports(() => {
			return (user && user.sports) ? user.sports
				.filter(sp => sp._joinData?.active)
				.map(sp => sp.name || ""):[]
		});
		setPrimaryAddress((user) ? user.preference?.addresses?.find(address => address?._joinData?.primary_address)?.id:undefined);
	}, [user])
	
	return (
		<IonPage>
			<CommonHeader title="Edit Profile" defaultHref="/user/profile/view" logout={true} />
			<IonContent className="ion-padding light-bg">
				<IonList>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel position="stacked">First Name</IonLabel>
								<IonInput
									type="email"
									onIonChange={(e) => {
										setFirstName(e.detail.value as string);
									}}
									name="text"
									placeholder="First Name"
									value={firstName}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="stacked">Last Name</IonLabel>
								<IonInput
									type="email"
									onIonChange={(e) => {
											setLastName(e.detail.value as string);
									}}
									name="text"
									placeholder="Last Name"
									value={lastName}
								/>
							</IonItem>
							<IonItem>
							<IonLabel position="stacked">Age</IonLabel>
								<IonInput type="number" value={age} placeholder="Enter Age" onIonChange={e => setAge(parseInt(e.detail.value!, 10))}></IonInput>
							</IonItem>
						</IonCol>
						<IonCol>
							<IonRow 
								className="ion-justify-content-center" 
								
							>
								{
									(user) ? (
									<ProfileImage
										icon_color="accent-two"
										icon_type="change"
										filename={user?.preference?.image ?? ""}
										size="50%"
										maxSize="300px"
										minSize="0px"
										icon_offset="0"
										refresh={userRefresh}
									/>):null 
								}
							</IonRow>
						</IonCol>
					</IonRow>

					{/* <IonItem
						style={{paddingTop: "20px"}}
					>
						<IonLabel>Toggle Theme</IonLabel>
						<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonIcon size="medium" icon={sunny} />
						<IonToggle checked={prefersDark} onIonChange={e => {
							saveToDatabase(e.detail.checked);
							toggleDarkTheme(e.detail.checked);
						}} />
						<IonIcon size="small" icon={moon} />
						</IonRow>
					</IonItem> */}
					<IonItem>
						<IonLabel position="stacked">Email Address</IonLabel>
						<IonInput
							type="email"
							onIonChange={(e) => {
								setEmail(e.detail.value as string);
							}}
							name="email"
							placeholder="Email"
							value={email}
						/>
					</IonItem>
					<GenderRadio selectedGender={gender} onChange={setGender} />
					<SportsDropdown multiple={true} onChange={setSelectedSports} selectedSports={selectedSports} />
					{(user && user.preference && user.preference.addresses && user.preference.addresses.length >= 1) ? (
						<AddressDropdown 
							onChange={setPrimaryAddress}
							selectedAddress={primaryAddress}
							preferenceID={user?.preference?.id}
							refreshUser={userRefresh}
						/>
					):(
						<NewAddressInputs
							lineOne={lineOne}
							lineTwo={lineTwo}
							city={city}
							state={state}
							zipCode={zipCode}
							setLineOne={setLineOne}
							setLineTwo={setLineTwo}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
						/>
					)}
					<IonItem>
						<IonLabel position="stacked">Biography</IonLabel>
            <IonTextarea 
							placeholder="I love playing sports..." 
							value={bio} 
							onIonChange={e => setBio(e.detail.value!)}
							auto-grow={false}
							rows={6}
							spellCheck={true}
						></IonTextarea>
          </IonItem>
					<IonButton
						color="main"
						expand="block"
						style={{ margin: 14 }}
						onClick={(e) => {
							if (!e.currentTarget) return;
							e.preventDefault();
							
							/* 
								construct new array describing sports objects 
							*/
							let newSports: Sport[] = [];

							// first we switch existing sports to true or false
							user?.sports.forEach((sport: Sport) => {
								let isActive = false;
								if (selectedSports.indexOf(sport.name || "") >= 0){
									isActive = true;
								}
								newSports.push({
									id: sport.id,
									name: sport.name,
									description: sport.description,
									_joinData: {
										active: isActive,
										rank: sport?._joinData?.rank,
										played: sport?._joinData?.played,
										wins: sport?._joinData?.wins
									}
								});
							})

							// then we add any new selected sports that weren't already included
							selectedSports.forEach((sport: string) => {
								let found_sport = user?.sports.find((sport_obj: Sport) => {
									return sport_obj.name === sport;
								})
								if (!found_sport){
									let sport_o = sports.find((sport_obj: Sport) => sport_obj.name === sport);
									if (sport_o){
										newSports.push({
											id: sport_o.id,
											name: sport_o.name,
											_joinData: {
												active: true
											}
										});
									}
								}
							})

							/*
								construct new addresses array
							*/
							let newAddresses: Address[] = (user?.preference?.addresses) || [];

							// loop existing to copy and update the default address
							if (user?.preference?.addresses && user.preference.addresses.length >= 1){
								newAddresses.forEach(address => {
									if (address && address._joinData){
										if (address.id === primaryAddress){
											address._joinData.primary_address = true;
										} else {
											address._joinData.primary_address = false;
										}
									}
								})
							} else {

								// let's validate all the address inputs
								if (lineOne == ""){
									toast({message: "Address Line 1 missing"}, true);
									return false;
								}

								if (city == ""){
									toast({message: "City missing"}, true);
									return false;
								}

								if (state == ""){
									toast({message: "Please select a state"}, true);
									return false;
								}

								if (zipCode == ""){
									toast({message: "Please enter a zip code"}, true);
									return false;
								}

								if (!validateAnyZip(zipCode)){
									toast({message: "Please enter a valid zip code"}, true);
									return false;
								}

								// if this is the first address, we'll just add a new address from the single address
								// state variables
								newAddresses.push({
									'address_line_1': lineOne,
									'address_line_2': lineTwo,
									'city': city,
									'state': state,
									'postal_code': zipCode,
									'lat': "",
									'lon': "",
									'_joinData': {
										'primary_address': true
									}
								});
							}

							// email validation
							if (!validateEmail(email)){
								toast({message: "Please enter a valid email"}, true);
								return false;
							}

							// configure the update profile args
							let args: ProfileUpdatePayload = {
								email: email,
								first_name: firstName,
								last_name: lastName,
								preference: {
									gender_id: (convertGenderStringToGenderObject(gender, genders))['id'],
									bio: bio,
									addresses: newAddresses,
									prefersDark
								},
								sports: newSports
							}
							if (age){
								args['preference']['age'] = age;
							}
							try {
								let response = Users.updateProfile(token ?? "", args);
								if (response){
									response.then(function(data){
										if (data && data.success){
											toast({message: "Profile Updated"});
											userRefresh();
										} else {
											toast({message: "Error Updating Profile"}, true);
										}
									});
								}
							} catch (error) {
								toast({message: "Error Updating Profile"}, true);
								console.log(error);
							}
						}}
					>
						Save
					</IonButton>
					
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ProfileEditPage