import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react"
import ProfileImage from "components/ProfileImage";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Challenges, Users } from "services/api";
import useAsyncEffect from "use-async-effect";
import styled from 'styled-components';
import { generateRandomString } from "services/util";
import CenteredSpinner from "components/CenteredSpinner";
import { timeAgo, toAppFormat } from "../services/time";
import useChallenges from "hooks/useChallenges";
import ReportChallenge from "components/ReportChallenge";
import IonTextTip from "components/IonTextTip";

interface Ratings {
  [id: string]: number
}

interface ChallengePageProps extends RouteComponentProps<{
	id?: string;
}> {}

const ChallengePage: React.FC<ChallengePageProps> = ({ match }) => {
  const { id } = match.params;
	const {token, userID} = useAuth();
  const [toast] = useToast("");
  const {refreshChallenges} = useChallenges();
  const [participants, setParticipants] = useState<User[]>([]);
  const [challenge, setChallenge] = useState<Challenge>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  // form state
  const [winner, setWinner] = useState<string>();
  const [players, setPlayers] = useState<ReactNode[]>([]);

  /**
   * 
   * @param users array of users who participanted in the game
   * @param issuerId the user id of the challenge issuer
   * @returns A react component displaying the users
   */
  const getPlayers = ((users: User[], issuerId: string) => {
    return (users) ? users.map((user, index) => {

      // check if we have the user info we need
      if (user && user.id && user.preference && user.preference.image){

        // establisih some tag defaults
        let colors = {
          textColor: 'constant-dark',
          color: 'success'
        }

        // array of tags we'll display
        let tags = [];

        // tags for if the challenge is complete
        if (challenge && challenge?.status === 'Complete' && challenge.reported_winner_id !== null){
          if (user.id === challenge.reported_winner_id){
            // adding the winner tag
            colors = {
              textColor: 'constant-light',
              color: 'success'
            }
            tags.push(<Tag key={`winner-${userID}`} color={colors.color} textColor={colors.textColor}>Winner</Tag>);
          } else {
            // we'll skip adding a loser tag for now
          }
        } else {
          // if it's any other status
          // show user invitation status
          if (user.invitation_status == "Accepted"){
            // if they accepted and the status is reporting, show their reporting instead that its been accepted
            if (challenge?.status === 'Reporting'){
              tags.push(<Tag key={`reporting-${userID}`} color="main" textColor="constant-light">Reporting</Tag>)
            } else {
              colors = {
                textColor: 'constant-dark',
                color: 'success'
              }
              tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
            }
          } else if (user.invitation_status == "Pending"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          } else if (user.invitation_status == "Dismissed"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          } else if (user.invitation_status == "Complete"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          }
        }

        // check if this person is the issuer
				/*
        if (parseInt(user.id) === issuerId){
          colors = {
            textColor: 'constant-dark',
            color: 'accent-two'
          }
          tags.unshift(<Tag key={`issuer-${userID}`} color={colors.color} textColor={colors.textColor}>Issuer</Tag>);
        }
				*/
        return (
          <IonItem key={generateRandomString()} lines="none" routerLink={`/user/view/${user.id}`} routerDirection="forward">
            <Player 
              className="ion-align-items-center" 
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none" 
                icon_color="primary"
                size="75px" 
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
                <IonRow>
                  {tags}
                </IonRow>
              </IonCol>
            </Player>
          </IonItem>
        )
      }
    }):[]
  });

  /**
   * Get's the status pill for the header
   * @param status
   * @returns void
   */
  const getStatusElement = (status: string | undefined) => {
    if (status === 'Dismissed' || status === 'Cancelled'){
      return <EventTag color="danger" textColor="constant-light" slot="end">{status}</EventTag>
    } else if (status === 'Complete'){
      return <EventTag color="success" textColor="constant-light" slot="end">{status}</EventTag>
    } else {
      return <EventTag color="main" textColor="constant-light" slot="end">{status}</EventTag>
    }
  }

  const getActions = (status: string | undefined) => {
    const Wrapper: React.FC = ({children}) => (
      <Actions>{children}</Actions>
    );
    if (status === 'Pending'){
      if (challenge?.user_id !== userID){
        return (
          <Wrapper>
            <IonButton
              size="default"
              color="success"
              style={{flex: 1}}
              onClick={ async (id) => {
                await Challenges.updateStatus(token ?? "", challenge?.id, "accepted");
                setTimeout(refreshChallenges, 1000);
              }}
            >
              Accept
            </IonButton>
            {(challenge && !challenge.frozen) ? (
            <IonButton
              size="default"
              color="main"
              style={{flex: 1}}
              routerLink={`/challenge/edit/${challenge?.id}`}
            >
              Modify
            </IonButton>
            ):null}
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <IonButton
              size="default"
              color="danger"
              style={{flex: 1}}
              onClick={async () => {
                await Challenges.cancel(token ?? "", challenge?.id);
                setTimeout(refreshChallenges, 1000);
              }}
            >
              Cancel
            </IonButton>
            {(challenge && !challenge.frozen) ? (
            <IonButton
              size="default"
              color="main"
              style={{flex: 1}}
              routerLink={`/challenge/edit/${challenge?.id}`}
            >
              Modify
            </IonButton>
            ):null}
          </Wrapper>
        );
      }
    } else if (status === 'Upcoming'){
      return (
        <Wrapper>
          <IonButton
            size="default"
            color="main"
            style={{flex: 1}}
            routerLink={`/challenge/edit/${challenge?.id}`}
          >
            Modify
          </IonButton>
        </Wrapper>
      );
    } else if (status === 'Reporting'){
			let found_user = challenge?.users.find(user => user.id === userID);
			let already_reported = (found_user && found_user?._joinData?.reported_winner_id !== null);
			if (!already_reported){
				return (
					<Wrapper>
						<IonButton
							size="default"
							color="main"
							style={{flex: 1}}
							onClick={() => {
								setModalOpen(true);
							}}
						>
							Report
						</IonButton>
					</Wrapper>
				);
			}
    }
  }

  useAsyncEffect(async () => {
    if (id){
      if (!token) return false;
      const result = await Challenges.view(token ?? "", id);
      if (result.success){
        setIsLoading(false);
        const { users, user_id } = result.payload;
        const participants = users.filter((user: User) => user.id !== userID);
        let ratings: Ratings = {};
        participants.forEach((user: User) => {
          ratings[user.id] = 5;
        });
        setChallenge(result.payload);
        setParticipants(participants);
        setPlayers(getPlayers(users, user_id));
      } else {
        console.log('redirect');
        toast({message: "Challenge does not exist"}, true);
        history.push('/');
      }
      return Challenges.viewCancel;
    }
  }, [token, userID, id])

  useEffect(() => {
    if (challenge){
      setPlayers(getPlayers(challenge.users, challenge.user_id));
      Challenges.setChallengeViewed(token ?? "", challenge.id);
    }
  },[challenge]);

	return (
		<IonPage>
			<IonHeader>
			<IonToolbar>
			  <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle color="main-text">Challenge</IonTitle>
        {getStatusElement(challenge?.status)}
			</IonToolbar>
		</IonHeader>
			<IonContent>
        {(!isLoading) ? (
			  <IonList>
          {(challenge) ? 
          (<IonCard>
            <IonListHeader>
              <CardHead>Details</CardHead>
            </IonListHeader>
						<DetailsHeader>
              <IonItem routerLink={`/user/view/${challenge.issuer.id}`} routerDirection="forward" lines="none">
                <div style={{paddingTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: "1"}}>
                  <ProfileImage
                    icon_type="none" 
                    icon_color="primary"
                    size="90px"
                    filename={challenge.issuer.preference.image ?? ""}
                    style={{margin: "15px 0", padding: "0", minWidth: "90px"}}
                  />
                  <DetailLabel style={{marginLeft: 'auto', marginRight: "0", padding: "0 0 0 25px"}}>
                    <div style={{paddingBottom: "10px"}}>
                      <div><IonText style={{fontSize: "13px"}}>Issuer:</IonText></div>
                      <div><IonText style={{fontSize: "18px"}}>{challenge.issuer.short_name}</IonText></div>
                    </div>
                    <DetailSubLabel>
                      <DetailLabelText>Created:</DetailLabelText>
                      <IonTextTip
                        title={toAppFormat(challenge.created)}
                      >
                        {timeAgo(challenge.created).agoString}
                      </IonTextTip>
                    </DetailSubLabel>
                    <DetailSubLabel>
                      <DetailLabelText>Modified:</DetailLabelText>
                      <IonTextTip
                        title={toAppFormat(challenge.modified)}
                      >
                        {timeAgo(challenge.modified).agoString}
                      </IonTextTip>
                    </DetailSubLabel>
                  </DetailLabel>
                </div>
              </IonItem>
            </DetailsHeader>
            <DetailList>
            <DetailRow lines="none">
              <DetailLabel>Start</DetailLabel>
              <DetailValueColumn>
                <DetailValue>{toAppFormat(challenge.challenge_start)}</DetailValue>
                <DetailValueSmall>{timeAgo(challenge.challenge_start).agoString}</DetailValueSmall>
              </DetailValueColumn>
            </DetailRow>
            <DetailRow lines="none">
              <DetailLabel>Sport</DetailLabel>
              <DetailValue>{challenge.sport?.name}</DetailValue>
            </DetailRow>
            <DetailRow lines="none">
              <DetailLabel>Location</DetailLabel>
              <DetailValue>{challenge.location}</DetailValue>
            </DetailRow>
            <DetailRow lines="none">
              <DetailLabel>Ranked</DetailLabel>
              <DetailValue>{(challenge.ranked) ? 'Yes':'No'}</DetailValue>
            </DetailRow>
            </DetailList>
          </IonCard>
          ):null}
          <IonCard>
            <IonListHeader>
              <CardHead>Participants</CardHead>
            </IonListHeader>
            <IonRadioGroup 
              value={winner} 
              onIonChange={e => {
                setWinner(e.detail.value)
              }}
              name="winner"
            >
              {(challenge?.user_id && challenge.users) ? getPlayers(challenge?.users ?? [], challenge.user_id):null}
            </IonRadioGroup>
          </IonCard>
          <IonCard>
            <IonListHeader>
              <CardHead>Messages</CardHead>
            </IonListHeader>
            <IonList>
              <IonItem lines="none">{challenge?.message}</IonItem>
              {challenge?.notes?.map((note: Note) => (
                <IonItem lines="none" key={generateRandomString()}>
                  <Note slot={(note.user_id === challenge.user_id) ? "start":"end"}>
                    <NoteMsg>{note.message}</NoteMsg>
                    <NoteDetails>
                      <NoteAuthor>{note?.user.short_name}</NoteAuthor>
                      <NoteTime>{toAppFormat(note?.created)}</NoteTime>
                    </NoteDetails>
                  </Note>
                </IonItem>
              ))}
            </IonList>
          </IonCard>
          {getActions(challenge?.status)}
          { (challenge) ? (
              <IonModal isOpen={modalOpen}>
                <ReportChallenge onCloseClick={() => setModalOpen(false)} challenge={challenge} />
              </IonModal>
            ):null
          }
        </IonList>
        ):<CenteredSpinner />}
			</IonContent>
		</IonPage>
	)
}

export default ChallengePage;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
`

const CardHead = styled.div`
  font-size: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--horizontal-gutter);
`;

const Tag = styled.div<{color: string, textColor: string}>`
  background: ${props => `var(--ion-color-${props.color})`};
  color: ${props => `var(--ion-color-${props.textColor})`};
  padding: 2px 15px;
  border-radius: 100px;
  text-align: center;
  width: min-content;
  font-size: 13px;
  margin: 5px 10px 0 0;
`;

const EventTag = styled.div<{color: string, textColor: string}>`
  background: ${props => `var(--ion-color-${props.color})`};
  color: ${props => `var(--ion-color-${props.textColor})`} !important;
  padding: 7px 15px;
  border-radius: 100px;
  text-align: center;
  width: min-content;
  margin-right: 25px;
`;

const DetailLabel = styled.div`
  font-size: 20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DetailValue = styled.div`
  text-align: right;
  padding-left: 20px;
`;

const DetailValueSmall = styled(DetailValue)`
  font-size: 12px;
`;

const DetailValueColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailLabelText = styled(IonText)`
`;

const DetailSubLabel = styled.div`
  font-size: 12px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between; 
  & > ${DetailLabelText} {
    margin-right: 10px;
  }
	@media (max-width: 275px){
		flex-direction: column;
		padding-bottom: 5px;
	}
`;

const Note = styled(IonCol)`
  background: #fff;
  border-radius: 5px;
  color: #333;
  width: 90%;
`;

const NoteMsg = styled.div`
  padding: 10px 10px 5px;
`;

const NoteDetails = styled(IonRow)`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 5px;
  margin-top: 5px;
  padding: 3px 10px;
  background: #ddd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: max-content;
`;

const NoteAuthor = styled.div`
  font-size: 12px;
`;

const NoteTime = styled.div`
  font-size: 10px;
`;

const DetailsHeader = styled.div`
  background: var(--ion-background-color);
  padding: 0px 16px;
  color: var(--ion-item-color, var(--ion-text-color, #000));
`;

const DetailRow = styled(IonItem)`
  align-items: center;
  --padding-top: 0;
  --padding-bottom: 0;
`;

const DetailList = styled.div`
  & > ${DetailRow}:nth-child(odd) {
    --background: var(--ion-background-color-shade);
  }
`;