import { IonCard, IonCardHeader, IonCardTitle, IonContent, IonPage, IonRouterLink } from '@ionic/react';
import Stats from '../components/Stats';
import useChallenges from '../hooks/useChallenges';
import { generateRandomString, getLinearGradient } from '../services/util';
import Podium from '../components/Podium';
import useUser from '../hooks/useUser';
import { RouteComponentProps } from 'react-router';
import useLeaders from 'hooks/useLeaders';
import styled from 'styled-components';
import CenteredSpinner from 'components/CenteredSpinner';
import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';
import WelcomeCard from 'components/WelcomeCard';
import RankCard from 'components/RankCard';
import WaveTitle from 'components/WaveTitle';
import LoggedInUser from 'components/LoggedInUser';
import { Challenges, Tournaments } from 'services/api';
import useAuth from 'hooks/useAuth';
import useAsyncEffect from 'use-async-effect';

const DashboardPage: React.FC<RouteComponentProps> = () => {
	const {user} = useUser();
	const {token} = useAuth();
	const {leaders, leadersLoading} = useLeaders();
	const [leaderNodes, setLeaderNodes] = useState<ReactNode[]>([]);
	const [leadersCache, setLeadersCache] = useState<User[]>([]);

	const [challengesModified, setChallengesModified] = useState<number>();
	const [tournamentsModified, setTournamentsModified] = useState<number>();

	const getLeaders = (leaders: User[]) => {
		if (JSON.stringify(leaders) !== JSON.stringify(leadersCache)){
			setLeadersCache(leaders);
			let newLeaderNodes = leaders.map(leader => (
				<RankCard
					key={generateRandomString()} 
					user={leader}
					lines="none"
					style={{"--background":"transparent"}}
					textColor="main"
				/>
			));
			setLeaderNodes(newLeaderNodes);
		} else {
			return leaderNodes;
		}
	}

	useAsyncEffect(async () => {
		let result = await Challenges.getNumModified(token ?? "");
		if (result.success){
			setChallengesModified(result.payload);
		}
	})
	
	useAsyncEffect(async () => {
		let result = await Tournaments.getNumModified(token ?? "");
		if (result.success){
			setTournamentsModified(result.payload);
		}
	})

	return (
		<IonPage>
			<IonContent fullscreen>
				<WaveTitle>
					<Title>
						<h1>Your Active Games</h1>
						<h2>Dashboard</h2>
					</Title>
				</WaveTitle>
				<div>
					<LoggedInUser />
				</div>
				{(
					(user && user.preference && !user.preference.verified_email) ||
					(user && user.preference && user.preference.addresses && user.preference.addresses.length <= 0)
				) ? <WelcomeCard user={user} />:null}
				<Stats />

				<CardRow>
					<Tile
						routerLink="/challenges" 
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							{(challengesModified) ? <Notification>{challengesModified}</Notification>:null}
							<TileTitle color="main">Your<br />Challenges</TileTitle>
						</IonCardHeader>
					</Tile>
					<Tile
						routerLink="/tournaments"
						style={{"--background": getLinearGradient('accent-two'), marginBottom: '0px'}}
					>
						<IonCardHeader>
							{(tournamentsModified) ? <Notification>{tournamentsModified}</Notification>:null}
							<TileTitle color="main">Your<br />Tournaments</TileTitle>
						</IonCardHeader>
					</Tile>
				</CardRow>


				<IonCard
					style={{"--background": getLinearGradient('accent-two')}}
				>
					<IonRouterLink  routerLink="/leaderboard">
					<IonCardHeader>
						<div style={{display: 'flex', alignItems: 'end', justifyContent: 'space-between'}}>
						<TileTitle color="main" style={{fontSize: '30px'}}>Leaderboard</TileTitle>
						<Podium height={45} width={17} />
						</div>
					</IonCardHeader>
					</IonRouterLink>
					{(leadersLoading) ? <CenteredSpinner />:
						(leaders && leaders.length && leaders.length > 0) ? getLeaders(leaders):null
					}
				</IonCard>
			</IonContent>
		</IonPage>
	);
};
 
export default DashboardPage;

const CardRow = styled.div`
	display: flex;
	& ion-card {
		flex: 1;
	}
	& ion-card:first-of-type {
		margin-right: 0;
	}
`;

const Title = styled.div`
	margin: 0;
	padding: 5px;
	& h1 {
		margin: 0;
		padding: 0;
		font-size: 27px;
	}
	& h2 {
		margin: 0;
		padding: 0;
		font-size: 30px;
		font-weight: bold;
	}
	font-family: var(--heading-font);
	font-weight: 700;
	text-align: center;
`;

const TileTitle = styled.div`
	font-size: 15px;
	font-family: var(--heading-font);
	color: black;
`;

const Tile = styled(IonCard)`
	position: relative;
	overflow: visible;
`;

const Notification = styled.div`
	background: red;
	color: white;
	position: absolute;
	top: -5px;
	right: -5px;
	width: 25px;
	height: 25px;
	border-radius: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	z-index: 999;
	font-family: var(--heading-font);
`;
