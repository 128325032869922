import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { Users } from "../services/api";
import useAuth from "./useAuth";
import useDynamicInputs from "./useDynamicInputs";
import { usePosition } from "./usePosition";

function useLeaders(filters: LeaderBoardFilters = {
	time: 'all-time',
	distance: 100,
	origin: "",
	genders: [],
	ageRange: {
		lower: 18,
		upper: 65
	},
	sport: 'all'
}, limit: number = 3){

	const {token} = useAuth();
	const [leadersLoading, setLeadersLoading] = useState<boolean>(false);
	const [leaders, setLeaders] = useState<User[]>();
	const {genders} = useDynamicInputs();
	const {position, prompt} = usePosition();

	useAsyncEffect(async () => {
		await updateLeaders();
		return () => {
      Users.getLeadersCancel();
		}
	}, [token])

	const checkOrigin = async (filters: any) => {
		console.log('==checkOrigin()');
		if (
				// origin not set as location string
				(filters['origin'] === null || filters['origin'] === '') &&
				// position has been aquired 
				(position?.coords?.latitude && position?.coords?.longitude)
		){
			console.log('using browser location as origin');
			const { latitude, longitude } = position.coords;
			filters['origin'] = {
				lat: latitude,
				lon: longitude
			}
		} else if (filters['origin'] !== "" && typeof filters['origin'] === 'string') {
			console.log({origin: filters['origin']});
		} else {
			console.log('prompting', filters);
			let newPosition = await prompt();
			console.log('newPosition', newPosition);
			if (newPosition !== null){
				console.log('setting new position', newPosition);
				filters['origin'] = newPosition.coords;
			} else {
				console.log('no location');
			}
		}
		return filters;
	}

  const updateLeaders = async () => {
		setLeadersLoading(true);
    filters = {
			...filters,
			genders: genders,
    };
		filters = await checkOrigin(filters);
		console.log('useLeaders', filters);
		try {
			let result = await Users.getLeaders(token ?? "", filters);
			if (result && result.success){
				setLeaders([...result.payload.splice(0, limit)]);
			}
		} catch (error){
			setLeadersLoading(false);
		}
		setLeadersLoading(false);
  }

	const getRefreshFunction = () => {
		return updateLeaders;
	}

	const LeadersPayload = {
		leaders,
		leadersLoading,
		refreshLeaders: getRefreshFunction()
	};
	
	return LeadersPayload;
}

export default useLeaders;