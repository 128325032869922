import { IonButton, IonInput, IonItem, IonLabel, IonList, IonRange, IonText } from "@ionic/react";
import { useContext } from "react";
import GenderCheckboxes from "./GenderCheckboxes";
import SportsDropdown from "./SportsDropdown";
import styled from 'styled-components';
import { FilterContext } from "../State/FilterContext";
import SearchDropdown from "./SearchDropdown";


const MatchSearch: React.FC = () => {
	const {state, dispatch} = useContext(FilterContext);

	// event handler for new gender selection
	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		let selectedGenders = state.matchFilters.genders;
		const newGenderArr: string[] = selectedGenders;
		if (checked){
			if (newGenderArr.indexOf(gender_change) < 0){
				newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
				newGenderArr.splice(index, 1);
			}
		}
		if (JSON.stringify(selectedGenders) !== JSON.stringify(state.matchFilters.genders)){
			dispatch({type: 'match_setGenders', payload: selectedGenders});
		}
  }

	// event handler for new sports selectiong
	const setSelectedSports = (selectedSports: string[]) => {
		if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)){
			dispatch({type: "match_setSelectedSports", payload: selectedSports});
		}
	}

	const updateAge = (e: any) => {
		dispatch({type: 'match_setAgeRange', payload: e.target.value});
	}

	return (
		<IonList lines="none">
			<SportsDropdown
				multiple={true}
				onChange={(sports) => {
					setSelectedSports(sports);
				}}
				selectedSports={state.matchFilters.sports}
			/>
			<IonItem>
				<IonLabel position="stacked">Location</IonLabel>
				<IonInput
					type="text"
					onIonChange={(e) => {
						dispatch({type: 'match_setOrigin', payload: e.detail.value})
					}}
					name="text"
					placeholder="City, State, or Zip Code"
					value={(typeof state.matchFilters.origin === 'string') ? state.matchFilters.origin:""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText>Distance</IonText>
					<LabelValue>({state.matchFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.matchFilters.distance}
					onIonChange={(e) => {
						dispatch({type: 'match_setDistance', payload: e.detail.value})
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText>Age</IonText>
					<LabelValue>{(
						state.matchFilters.ageRange &&
						state.matchFilters.ageRange.lower &&
						state.matchFilters.ageRange.upper) ? 
						`(${state.matchFilters.ageRange.lower} - ${state.matchFilters.ageRange.upper})`:null}</LabelValue>
				</RowLabel>
				<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={state.matchFilters.ageRange} onIonChange={updateAge}>
					<IonText slot="start">18</IonText>
					<IonText slot="end">100</IonText>
				</IonRange>
			</IonItem>
			<GenderCheckboxes
				selectedGenders={state.matchFilters.genders}
				onChange={updateGenderSelection}
			/>
			<IonButton
			  color="main"
			  expand="block"
			  style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({type: 'setSearchType', payload: 'match'});
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default MatchSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	--width: 100% !important;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;