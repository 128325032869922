import { IonBackButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { close, logOut, logOutOutline } from "ionicons/icons";
import { AuthenticationService } from "../services/auth";

interface CommonHeaderProps {
	title?: string
	defaultHref?: string
	logout?: boolean
	onClose?: () => void
}

const CommonHeader: React.FC<CommonHeaderProps> = ({title, defaultHref, onClose, logout = false}) => {
	return (
		<IonHeader>
			<IonToolbar>
			  <IonButtons slot="start">
          <IonBackButton defaultHref={defaultHref} />
        </IonButtons>
				<IonTitle color="main-text">{title}</IonTitle>
				{ (logout) ? (
					<IonIcon 
						icon={logOutOutline}
						slot="end"
						size="large"
						color="main-text"
						style={{
							marginRight: "var(--toolbar-icon-right-margin)"
						}}
						onClick={(e) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();
							AuthenticationService.logout();
						}}
					/>):null
				}
				{ (onClose) ? (
					<IonIcon 
						icon={close}
						slot="end"
						size="large"
						color="main-text"
						style={{
							marginRight: "var(--toolbar-icon-right-margin)"
						}}
						onClick={(e) => {
							onClose();
						}}
					/>):null
				}
			</IonToolbar>
		</IonHeader>
	)
}

export default CommonHeader;