import { IonCol, IonRow, IonText } from "@ionic/react";
import { ReactNode, useEffect, useState } from "react";
import { generateRandomString } from "../services/util";
import Card from "./Card";
import CenteredSpinner from "./CenteredSpinner";
import Challenge from "./Challenge";
import ChallengePrompt from "./ChallengePrompt";

interface ChallengesListProps {
	title: string
	contextual_user_id: string
	challenges: Challenge[]
	searchPrompt?: boolean
	hideTitleOnNoResults?: boolean
	allowViewChallenge?: boolean
	showActions?: boolean
	refreshChallenges: () => void
}

const ChallengesList: React.FC<ChallengesListProps> = ({
	title,
	contextual_user_id,
	challenges,
	searchPrompt = true,
	hideTitleOnNoResults = true,
	allowViewChallenge = true,
	showActions = true,
	refreshChallenges
}, props) => {
	const [nodes, setNodes] = useState<ReactNode[]>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
  const mapChallenges = () => {
		if (challenges && challenges.map !== undefined){
			const newNodes = challenges.map((challenge) => {
				let passProps = {
					challenge,
					issuer: challenge.issuer,
					recipients: challenge.recipients,
					contextual_user_id,
					boxType: 'solid',
					isContextUserIssuer: challenge.issuer.id === contextual_user_id,
					allowViewChallenge,
					showActions,
					refreshChallenges
				}
				return (
					<Challenge key={generateRandomString()} {...passProps} {...props} />
				)
			})
			setNodes(newNodes);
			setTimeout(() => {setIsLoading(false)}, 0);
		} else {
			setNodes([]);
		}
	}
	useEffect(() => {
		mapChallenges();
	}, [challenges])

	return (
		(isLoading) ? (<CenteredSpinner />):(
			<IonCol>
				<Card title={
						(challenges && challenges.length !== undefined && challenges.length === 0) ? 
							((hideTitleOnNoResults) ? null:`${title} Challenges`)
							:
						(`${title} (${(challenges && challenges.length !== undefined) ? challenges.length:0})`)
					}
				>
					{(challenges && challenges.length === 0) ? (
						(searchPrompt) ? <ChallengePrompt msg={`No ${title} Challenges`} />:(
							<IonRow className="ion-justify-content-center">
								<IonText>No Challenges</IonText>
							</IonRow>
						)):nodes}
				</Card>
			</IonCol>
		)
  );
}

export default ChallengesList;