import { IonButton, IonContent, IonModal, IonPage } from "@ionic/react";
import CenteredSpinner from "components/CenteredSpinner";
import CommonHeader from "components/CommonHeader";
import ErrorMessage from "components/ErrorMessage";
import PageTitle from "components/PageTitle";
import TournamentPromptButton from "components/TournamentPromptButton";
import TournamentsList from "components/TournamentsList";
import useAuth from "hooks/useAuth";
import useTournaments from "hooks/useTournaments";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TournamentCreate from "./TournamentCreate";

const TournamentsPage: React.FC = () => {
	const {userID} = useAuth();
	const [modalOpen, setModalOpen] = useState(false);
	const {
		tournaments, tournamentsLoading, tournamentsError,
		organizing, organizingLoading, organizingError,
		refreshTournaments, pollTournaments} = useTournaments();
	const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
	useEffect(() => {
		console.log('starting tournaments page polling');
		intervalRef.current = setInterval(() => {
			pollTournaments();
		}, 1000);
		return () => {
			if (intervalRef.current){
				console.log('stopping tournaments page polling');
				clearInterval(intervalRef.current);
			}
		}
	}, []);

	return (
		<IonPage>
			<CommonHeader title={`Tournaments`}/>
			<IonContent>
				<div style={{ margin: 14 }}>
					<TournamentPromptButton />
					<IonButton
						color="main"
						expand="block"
						style={{ marginTop: 14 }}
						onClick={(e) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();
							setModalOpen(true);
						}}
					>
						Create Tournament
					</IonButton>
				</div>
				<IonModal isOpen={modalOpen}>
					<TournamentCreate onCloseClick={() => setModalOpen(false)} />
				</IonModal>
				<div>
					{
						(!organizingError && !organizingLoading && organizing.length > 0) ?
						<div>
							<PageTitle size="15px">Organizing</PageTitle>
							<TournamentsList tournaments={organizing} user_id={userID}/>
						</div>:null
					}
					{
						(tournamentsLoading) ? (
							<CenteredSpinner />
						):((tournamentsError) ? (
							<ErrorMessage retryMethod={refreshTournaments} >{tournamentsError}</ErrorMessage>
						):(tournaments && tournaments.length > 0) ? (
							<div>
								<PageTitle size="15px">Participating</PageTitle>
								<TournamentsList tournaments={tournaments} user_id={userID}/>
							</div>
						):null)
					}

					{
						(
							!(!organizingError && !organizingLoading && organizing.length > 0) &&
							!(tournamentsLoading) &&
							!(tournamentsError) &&
							!(tournaments && tournaments.length > 0)
						) ? (
							<NoneMessage>
								<h1>No Tournaments</h1>
								<div>
									Search your area for<br />open tournaments to join
								</div>
								<TournamentPromptButton />	
							</NoneMessage>
						):null
					}
				</div>
			</IonContent>
		</IonPage>
	)
}

export default TournamentsPage;

const NoneMessage = styled.div`
	text-align: center;
	padding: var(--horizontal-gutter);	
	& h1 {
		font-size: 30px;
		font-family: var(--heading-font);
	}
	& div {
		padding-bottom: 20px;
	}
`;