import useDynamicInputs from "hooks/useDynamicInputs";
import { useReducer, useEffect, createContext } from "react";

interface FilterContext {
  dispatch?: any
  state: {
    matchFilters: MatchSearch
		tournamentFilters: TournamentSearch
		searchType: SearchType
  }
}

let initialContext = { 
	state: {
		matchFilters: {
			sports: [],
			origin: "",
			distance: 200,
			ageRange: {
				lower: 18,
				upper: 65
			},
			ranked: false,
			genders: []
		},
		tournamentFilters: {
			sports: [],
			origin: "",
			distance: 200,
			startDate: "",
			endDate: ""
		},
		searchType: 'match' as SearchType
	}
};

let FilterContext = createContext<FilterContext>(initialContext);

let reducer = (state: any, action: StateAction) => {
	switch(action.type){

		/* MATCH FILTER CASES */
		case 'match_setSelectedSports':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					sports: action.payload
				}
			}
		case 'match_setSelectedGenders':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					genders: action.payload
				}
			}
		case 'match_setOrigin':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					origin: action.payload
				}
			}
		case 'match_setDistance':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					distance: action.payload
				}
			}
		case 'match_setAgeRange':
			return {
				...state,
				matchFilters: {
					...state.matchFilters,
					ageRange: action.payload
				}
			}
		
		/* TOURNAMENT FILTER CASES */
		case 'tournament_setSelectedSports':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					sports: action.payload
				}
			}
		case 'tournament_setOrigin':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					origin: action.payload
				}
			}
		case 'tournament_setDistance':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					distance: action.payload
				}
			}
		case 'tournament_setStartDate':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					startDate: action.payload
				}
			}
		case 'tournament_setEndDate':
			return {
				...state,
				tournamentFilters: {
					...state.tournamentFilters,
					endDate: action.payload
				}
			}

		/* SET SEARCH TYPE */
		case 'setSearchType':
			return {
				...state,
				searchType: action.payload
			}
	}
	return state;
}

function FilterContextProvider(props: any){
	const initialState = initialContext.state;
	const {sports, genders} = useDynamicInputs();
	let [state, dispatch] = useReducer(reducer, initialState);
	let value = { state, dispatch };

	useEffect(() => {
		if (sports.map){
			dispatch({type: 'match_setSelectedSports', payload: sports.map(sport => sport.name)});
			dispatch({type: 'tournament_setSelectedSports', payload: sports.map(sport => sport.name)});
		}
	},[sports]);

	useEffect(() => {
		if (genders.map){
			dispatch({type: 'match_setSelectedGenders', payload: genders.map(gender => gender.name)});
			dispatch({type: 'tournament_setSelectedGenders', payload: genders.map(gender => gender.name)});
		}
	},[genders]);

	return (
		<FilterContext.Provider value={value}>{props.children}</FilterContext.Provider>
	)
}

let FilterContextConsumer = FilterContext.Consumer;

export {
	FilterContext,
	FilterContextConsumer,
	FilterContextProvider
}