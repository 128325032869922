import { useHistory } from "react-router-dom";
import styled from "styled-components";

const TitleCardSimple: React.FC<{
	showSubtitle?: boolean
}> = ({showSubtitle = false}) => {
	const history = useHistory();
	return (
		<TitleContainer
			onClick={() => history.push('/')}
		>
			<Title><div>Your</div><div>Active</div><div>Games</div></Title>
			{showSubtitle && <Subtitle>Find and challenge players close to home</Subtitle>}
		</TitleContainer>
	);
}

export default TitleCardSimple;

const TitleContainer = styled.div`
	color: var(--ion-color-accent-two);
	font-family: var(--heading-font);
`;

const Title = styled.div`
	font-size: clamp(30px, 20vw, 35px);
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	margin: 0 0 8px;
	& div {
		margin: -4px 0;
	}
`;

const Subtitle = styled.div`
	font-size: clamp(20px, 5vw, 20px);
	font-weight: normal;
`;