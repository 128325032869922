import { IonRouterLink, IonIcon } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import styled from "styled-components";


const AuthNav: React.FC = () => {
	return (
		<BottomLinks>
			<IonRouterLink href="/about">About</IonRouterLink>
			<Dot icon={ellipse} color="accent-two" />
			<IonRouterLink href="/contact">Contact Us</IonRouterLink>
		</BottomLinks>
	);
}

export default AuthNav;

const Dot = styled(IonIcon)`
	font-size: 4px;
	margin: 0 10px;
`;

const BottomLinks = styled.div`
	padding: 20px 0 0;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	& ion-router-link {
		color: var(--ion-color-accent-two);
		font-family: var(--heading-font);
	}
`;