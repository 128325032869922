import { IonPage, IonContent, IonRow, IonText, IonSpinner, IonCol, IonItem, IonRadio, IonCard, IonList, IonListHeader, IonRadioGroup, IonButton } from "@ionic/react"
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Challenges, Tournaments } from "services/api";
import { generateRandomString } from "services/util";
import styled from "styled-components";
import CommonHeader from "./CommonHeader"
import ProfileImage from "./ProfileImage";

const ReportTournament: React.FC<{
  onCloseClick:  () => void
  tournament: Tournament
}> = ({tournament, onCloseClick}) => {
	const {token, userID} = useAuth();
  const [toast] = useToast("");
	const [submitted, setSubmitted] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [playerRadios, setPlayerRadios] = useState<ReactNode[]>([]);
	const [winner, setWinner] = useState<string>();
	const history = useHistory();
	
  const getPlayerRadios = ((users: User[]) => {
    return (users) ? users.map((user, index) => {
      if (user && user.id && user.preference){
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player
              className="ion-align-items-center"
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none"
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
              </IonCol>
            </Player>
            <IonRadio slot="end" value={user.id} />
          </IonItem>
        )
      }
    }):[]
  });

	useEffect(() => {
		const { users } = tournament;
		console.log('users', users);
		setPlayerRadios(getPlayerRadios(users));
	}, [tournament])

	return (
		<IonPage>
			<CommonHeader title={`Tournament Report`} onClose={onCloseClick} />
			<IonContent>
			{(!isLoading) ? (
        (submitted) ? (
          <IonRow style={{padding: '10px'}}>
            <IonText style={{fontSize: '20px'}}>Thanks for submitting the tournament report</IonText>
          </IonRow>
        ):(
					<IonList>
						<IonCard>
							<IonListHeader>
								<Question>Who won?</Question>
							</IonListHeader>
							<IonRadioGroup 
								value={winner} 
								onIonChange={e => {
									setWinner(e.detail.value)
								}}
								name="winner"
							>
								{playerRadios}
							</IonRadioGroup>
						</IonCard>
						<IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();
              let args = {
                winner
              };
              if (args.winner === undefined){
                toast({message: "Please select a winner"}, true);
              } else {
								const response = Tournaments.report(token ?? "", args, tournament.id); 
								if (response){
									response.then(function(data: any){
										if (data.success){
											toast({message: "Report sent successfully"});
											history.goBack();
										} else {
											toast({message: data.message}, true);
										}
									});
								}
              }
            }}
          >
            Submit
          </IonButton>
					</IonList>
				)
			):(
					<IonRow style={{height: "100%"}} class="ion-justify-content-center ion-align-items-center">
						<div>
							<IonSpinner />
						</div>
					</IonRow>
				)
			}
			</IonContent>
		</IonPage>
	)
}

export default ReportTournament;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
`

const Question = styled.div`
  font-size: 20px;
`;