import { IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText, IonTextarea, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import useToast from "../hooks/useToast";
import { Challenges } from "../services/api";
import PillRadios from "./PillRadios";
import styled from 'styled-components';
import { informationCircleOutline } from "ionicons/icons";
import Information from "./Information";
import { joinISODateAndTime } from "services/time";

const ChallengeForm: React.FC<{
  onCloseClick:  () => void
  user: any
}> = ({onCloseClick, user}) => {
  const {token} = useAuth();
  const [sport, setSport] = useState<string>(user.sports[0]?.name || "");
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [ranked, setRanked] = useState<boolean>(false);
  const [toast] = useToast("Hello");

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Challenge
            <Subtitle>{user.short_name}</Subtitle>
          </IonTitle>
          <IonIcon
            size="large"
            style={{
              marginRight: "var(--toolbar-icon-right-margin)"
            }}
            icon="close" 
            slot="end" 
            onClick={onCloseClick}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem className="form-input" data-name="sport">
            <IonLabel>Sport</IonLabel>
            <PillRadios
              options={user.sports.map((each: Sport) => each.name)}
              value={sport}
              onChange={(selectedSport:string) => setSport(selectedSport)}
            />
          </IonItem>
          <TimeRow>
            <IonItem className="form-input" data-name="date">
              <IonLabel position="stacked">Start Date</IonLabel>
              <IonDatetime
                name="date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="time">
                <IonLabel position="stacked">Start Time</IonLabel>
                <IonDatetime
                  name="time"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked">Location</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={location}
              onIonChange={(e) => {
                  setLocation(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem>
              <LabelRow>
                <IonLabel>Ranked</IonLabel>
                <Information
                  style={{marginLeft: '5px'}}
                  alertOptions={{
                    header: "Information",
                    message: "Ranked challenges will affect your place in the leaderboard and the statistics shown on the dashboard<br /><br />Non Ranked Challenges do not cost any credits"
                  }}
                />
              </LabelRow>
              <IonToggle checked={ranked} onIonChange={e => setRanked(e.detail.checked)} />
          </IonItem>
          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked">Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={6}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();

              if (!sport){
                toast({message: 'Select a sport'}, true);
                return;
              }

              if (!location){
                toast({message: 'Add a location'}, true);
                return;
              }

              if (!date || !time){
                toast({message: 'Please set a date and time'}, true);
                return;
              }

              let args = {
                sport,
                location,
                date: joinISODateAndTime(date, time),
                message,
                ranked,
                ids: [user.id]
              }
              let response;
              try {
                response = Challenges.issue(token ?? "", args);
                if (response){
                  response.then(function(data){
                    if (data && data.success){
                      toast({message: "Challenge Sent"});
                      onCloseClick();
                    } else {
                      console.log(data);
                      toast({message: "Problem Submitting Challenge"}, true);
                    }
                  })
                }
              } catch (e) {
                console.log(e);
                setLoading(false);
                setError('Problem Issueing Challenge');
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
      </IonContent>
    </>
  )
}

export default ChallengeForm;

const Subtitle = styled(IonText)`
  font-size: 12px;
  display: block;
`

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
`;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;