import { IonCol, IonIcon, IonItem, IonRow, IonText } from "@ionic/react"
import { clipboardOutline, location } from 'ionicons/icons';
import { truncate } from "../services/util";

interface TournamentCardProps {
  tournament: any
}

const TournamentCard: React.FC<TournamentCardProps> = ({tournament}) => {
  
  return (
    <IonItem routerLink={`/tournament/view/${tournament.id}`} detail={true}>
      <IonCol style={{padding: '10px 0'}}>
        <IonRow className="ion-align-items-center ion-justify-content-between" style={{paddingBottom: '5px'}}>
          <IonText style={{fontSize: '18px'}}>{truncate(tournament?.title ?? "", 25)}</IonText>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol style={{maxWidth: "22px"}}>
            <IonIcon style={{ marginRight: "5px", fontSize: "13px" }} color="main-text" icon={clipboardOutline} />
          </IonCol>
          <IonCol>
            <IonRow>
             <IonText style={{ fontSize: '13px'}}>{tournament.openSlots} open slots</IonText>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol style={{maxWidth: '22px'}}>
            <IonIcon style={{ marginRight: "5px", fontSize: '13px' }} color="main-text" icon={location} />
          </IonCol>
          <IonCol>
            <IonRow>
              <IonText style={{fontSize: '12px'}}>{tournament.location}</IonText>
              {(tournament && tournament.distance_miles !== undefined && tournament.distance_miles !== null) ?
              (<IonText style={{fontSize: '12px', marginLeft: '10px'}}>({tournament.distance_miles?.toFixed(1)} miles away)</IonText>):null}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonItem>
	)
}

export default TournamentCard;
