import { IonList, IonRow, IonSpinner } from "@ionic/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { FilterContext } from '../State/FilterContext'
import { generateRandomString } from "../services/util";
import RankCard from "./RankCard";
import TournamentCard from "./TournamentCard";
import UserCard from "./UserCard";



const SearchResults: React.FC<SearchResultsProps> = ({results, filters, searchType = "match" }) => {
  const [resultsList, setResultsList] = useState<ReactNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {state} = useContext(FilterContext);

  const listResults = () => {
    let list = [];
    if (results.map !== undefined){
      if (searchType === "match"){
        list = results.map((item: any) => (
          <UserCard user={item} key={generateRandomString()}/>
        ));
      } else if (searchType === "tournament"){
        list = results.map((item: any) => (
          <TournamentCard tournament={item} key={generateRandomString()} />
        ));
      } else if (searchType === "leaderboard"){
        list = results.map((item: any) => (
          <RankCard user={item} filters={filters} key={generateRandomString()} />
        ));
      }
    }
    setIsLoading(false);
    return list;
  }

  useEffect(() => {
    setResultsList(listResults());
  }, [results])

  return (
    (isLoading) ? (
    <IonRow className="ion-justify-content-center ion-align-items-center" style={{padding: '15px'}}>
      <IonSpinner
        style={{
          width: "100%",
          height: `30px`
        }}
        name="dots"
      />
    </IonRow>
  ):(
      <IonList>
        {resultsList}
      </IonList>
    )
  )
}

export default SearchResults