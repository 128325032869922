import { IonPage, IonContent } from "@ionic/react";
import CommonHeader from "components/CommonHeader";
import WaveHeader from "components/WaveHeader";
import { RouteComponentProps } from "react-router";

const StorePage: React.FC<RouteComponentProps> = ({ match }, props) => {
  return (
		<IonPage>
      <CommonHeader title="Buy Credits" defaultHref="/user/profile" />
			<IonContent>
      </IonContent>
    </IonPage>
  )
}

export default StorePage;