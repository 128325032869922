import { Redirect, Route, Switch } from 'react-router-dom';
import {
	IonApp,
	IonIcon,
	IonPage,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { person, search, home } from 'ionicons/icons';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import SplashPage from './pages/auth/SplashPage';
import LoginPage from './pages/auth/LoginPage';
import AboutPage from './pages/auth/About';
import ForgotPage from './pages/auth/ForgotPage';
import ChallengePage from 'pages/ChallengePage';
import RegistrationPage from './pages/auth/RegistrationPage';
import ResultsPage from './pages/ResultsPage';
import useAuth from './hooks/useAuth';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './global.css';
import styled from 'styled-components';
import LeaderboardPage from './pages/LeaderboardPage';
import useDarkTheme from './hooks/useDarkTheme';
import StorePage from 'pages/StorePage';
import ProfileImage from 'components/ProfileImage';
import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef } from 'react';
import useUser from 'hooks/useUser';
import TournamentPage from 'pages/TournamentPage';
import LoadingPage from 'pages/LoadingPage';
import ProfileEditPage from 'pages/ProfileEditPage';
import ModifyChallengePage from 'pages/ModifyChallengePage';
import { AppContextProvider } from './State/AppContext';
import SetupPage from 'pages/SetupPage';
import { UserContext } from 'State/UserContext';
import ContactPage from 'pages/auth/ContactPage';
import ChallengesPage from 'pages/ChallengesPage';
import TournamentsPage from 'pages/TournamentsPage';
import AccountSettingsPage from 'pages/AccountSettingsPage';
import ModifyTournamentPage from 'pages/ModifyTournamentPage';

const SetupRoutes = () => {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route exact path="/setup" component={SetupPage} />
				<Route exact path="/" render={() => <Redirect to='/setup' />} />
				<Route render={() => <Redirect to="/setup" />} />
			</IonRouterOutlet>
		</IonReactRouter>
	)
}

const PublicRoutes = () => {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/register" component={RegistrationPage} />
				<Route exact path="/forgot" component={ForgotPage} />
				<Route exact path="/about" component={AboutPage} />
				<Route exact path="/contact" component={ContactPage} />
{/* 
				<Route path="/search/:tab(match)" render={(props) => <SearchPage {...props} />} />
				<Route path="/search/:tab(tournament)" render={(props) => <SearchPage {...props} />} />
				<Route exact path="/results" render={(props) => <ResultsPage {...props} />} /> 
*/}

				<Route exact path="/" component={SplashPage} />
				<Route render={() => <Redirect to="/" />} />
			</IonRouterOutlet>
		</IonReactRouter>
	);
};

class DebugRouter extends IonReactRouter {
  constructor(props: any){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

const PrivateRoutes: React.FC<{user: User}> = ({user}) => {
	return (
		<IonReactRouter>
			<IonPage>
				<IonTabs>
					
					{/*
							Actual Authenticated Routes
					*/}
					<IonRouterOutlet>
						<Switch>
							<Route exact path="/dashboard" component={DashboardPage} />

							<Route path="/search/:tab(match)" render={(props) => <SearchPage {...props} />} />
							<Route path="/search/:tab(tournament)" render={(props) => <SearchPage {...props} />} />
							<Route exact path="/results" render={(props) => <ResultsPage {...props} />} />

							<Route exact path="/account-settings" component={AccountSettingsPage} />
							<Route exact path="/user/profile/edit" component={ProfileEditPage} />
							<Route exact path="/user/profile/view" component={ProfilePage} />
							<Route exact path="/user/view/:id" render={(props) => <ProfilePage {...props} />} />
							<Route exact path="/challenge/view/:id" component={ChallengePage} />
							<Route exact path="/challenge/edit/:id" component={ModifyChallengePage} />
							<Route exact path="/tournament/view/:tournament_view_id" component={TournamentPage} />
							<Route exact path="/tournament/edit/:id" component={ModifyTournamentPage} />
							<Route exact path="/leaderboard" component={LeaderboardPage} />
							<Route exact path="/store" component={StorePage} />
							<Route exact path="/challenges" component={ChallengesPage} />
							<Route exact path="/tournaments" component={TournamentsPage} />
							<Route exact path="/" render={() => <Redirect to='/dashboard' />} />
							<Route render={() => <Redirect to="/dashboard" />} />
						</Switch>
					</IonRouterOutlet>

					{/*
							Tabs that show at bottom when we're in this section of pages
					*/}
					<IonTabBar slot="bottom">
						<IonTabButton tab="dashboard" href="/dashboard">
							<IonIcon icon={home} />
							{/* <IonBadge>6</IonBadge> */}
						</IonTabButton>
						<IonTabButton tab="search" href="/search/match">
							<IonIcon icon={search} />
						</IonTabButton>
						<IonTabButton tab="profile" href="/user/profile/view">
							{
								(user?.id && user?.preference?.image) ?
								(<ProfileImage
									icon_color="accent-two"
									icon_type={"none"}
									filename={user.preference.image}
									size="50px"
									icon_offset="-10px"
								/>):(<IonIcon icon={person} />)
							}
						</IonTabButton>
					</IonTabBar>
				</IonTabs>
			</IonPage>
		</IonReactRouter>
	);
};

const AppInner: React.FC = () => {
	const {loggedIn, loading, token} = useAuth();
	const {userRefresh} = useUser();
	const {state: { user, userLoading, userError }} = useContext(UserContext);
	useDarkTheme();


	// setup polling for verified email check
	const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
	useEffect(() => {
		if (user?.preference?.verified_email){
			if (intervalRef.current){
				clearInterval(intervalRef.current);
			}
		} else {
			intervalRef.current = setInterval(() => {
				userRefresh();
			}, 5000);
		}
		return () => {
			if (intervalRef.current){
				clearInterval(intervalRef.current);
			}
		}
	}, [user]);

	if (loading || userLoading && userError === null){
		return <LoadingPage />
	} else {
		if (loggedIn && user && token){
			if (!user?.preference?.verified_email){
				return <SetupRoutes />
			} else {
				return <PrivateRoutes user={user} />
			}
		}

		return <PublicRoutes />
	}
}

const App: React.FC = () => (
	<AppContextProvider>
		<IonApp>
			<AppInner />
		</IonApp>
	</AppContextProvider>
)

export default App;


