import { IonButton, IonCard, IonCol, IonContent, IonIcon, IonItem, IonList, IonListHeader, IonPage, IonRadio, IonRadioGroup, IonRow, IonSpinner, IonText } from "@ionic/react"
import CommonHeader from "components/CommonHeader"
import ProfileImage from "components/ProfileImage";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { ReactNode, useEffect, useState } from "react";
import { Challenges } from "services/api";
import styled from 'styled-components';
import { generateRandomString } from "services/util";
import StarRatingComponent from 'react-star-rating-component';
import useStateCallback from "hooks/useStateCallback";
import { star, starHalf } from "ionicons/icons";
import useChallenges from "hooks/useChallenges";
import useDarkTheme from "hooks/useDarkTheme";

interface Ratings {
  [id: string]: number 
}

const ReportChallenge: React.FC<{
  onCloseClick:  () => void
  challenge: Challenge
}> = ({onCloseClick, challenge}) => {

	const {token, userID} = useAuth();
	const {refreshChallenges} = useChallenges();
  const [toast] = useToast("");
  const [participants, setParticipants] = useState<User[]>([]);
  const [submitted, setSubmitted] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {prefersDark} = useDarkTheme();

  // form state
  const [winner, setWinner] = useState<string>();
  const [ratings, setRatings] = useStateCallback<Ratings>({})

  const [ratingRows, setRatingRows] = useState<ReactNode[]>([]);
  const getRatingRows = ((users: User[]) => {
    return (users) ? users.map((user: User) => {
      if (
        user &&
        user.preference &&
        user.id &&
        ratings[user.id] != undefined
      ){
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player 
              className="ion-align-items-center"
              isSelected={false}
            >
              <ProfileImage
                icon_type="none" 
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
              </IonCol>
            </Player>
            <div slot="end" style={{fontSize: "15px"}}>
              <StarRatingComponent
                name="user-rating"
                value={ratings[user.id]}
                editing={true}
                emptyStarColor="#777"
                onStarClick={(e) => handleRating(user.id, e)}
                renderStarIcon={() => <IonIcon icon={star} />}
                renderStarIconHalf={() => <IonIcon icon={starHalf} />}
              />
            </div>
          </IonItem>
        )
      }
    }):[];
  })
  
  const [playerRadios, setPlayerRadios] = useState<ReactNode[]>([]);
  const getPlayerRadios = ((users: User[]) => {
    return (users) ? users.map((user, index) => {
      if (user && user.id && user.preference){
        console.log(user.preference.image);
        return (
          <IonItem key={generateRandomString()} lines="none">
            <Player
              className="ion-align-items-center"
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none"
                icon_color="primary"
                size="75px"
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
              </IonCol>
            </Player>
            <IonRadio slot="end" value={user.id} />
          </IonItem>
        )
      }
    }):[]
  });

  // Catch Rating value
  const handleRating = (id: string, rate: number) => {
    setRatings({
      [id]: rate
    }, () => {});
  }

  useEffect(() => {
    const { users, id } = challenge;

    // prevent report form showing up when it's already been reported
    let found_user = users.find((user: User) => user.id === userID);
    if (found_user && found_user?._joinData?.reported_winner_id !== null){
      setSubmitted(true);
      
      onCloseClick();
    }

    const participants = users.filter((user: User) => user.id !== userID);
    let ratings: Ratings = {};
    participants.forEach((user: User) => {
      ratings[user.id] = 5;
    });
    setRatings(ratings, () => {
      setParticipants(participants);
      console.log('setRatings callback users', users);
      setPlayerRadios(getPlayerRadios(users));
      setIsLoading(false);
    });
  }, [token, userID, challenge])

  useEffect(() => {
    setRatingRows(getRatingRows(participants));
  }, [participants, ratings])

	return (
		<IonPage>
			<CommonHeader title={`Challenge Report`} onClose={onCloseClick} />
			<IonContent>
        {(!isLoading) ? (
        (submitted) ? (
          <IonRow style={{padding: '10px'}}>
            <IonText style={{fontSize: '20px'}}>Thanks for submitting a challenge report</IonText>
          </IonRow>
        ):(
			  <IonList>
          <IonCard>
            <IonListHeader>
              <Question>Who won?</Question>
            </IonListHeader>
            <IonRadioGroup 
              value={winner} 
              onIonChange={e => {
                setWinner(e.detail.value)
              }}
              name="winner"
            >
              {playerRadios}
            </IonRadioGroup>
          </IonCard>
          <IonCard>
          <IonListHeader>
            <Question>Rate Players</Question>
          </IonListHeader>
          <IonCol>
            {ratingRows}
          </IonCol>
          </IonCard>
          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (!e.currentTarget) return;
              e.preventDefault();
              let args = {
                ratings, 
                winner
              };
              if (args.winner === undefined){
                toast({message: "Please select a winner"}, true);
              } else {
                const { id } = challenge;
                if (id){
                  const response = Challenges.report(token ?? "", args, id);
                  if (response){
                    response.then(function(data: any){
                      if (data.success){
                        toast({message: "Report sent successfully"});
												refreshChallenges()
                        onCloseClick();
                      } else {
                        toast({message: data.message}, true);
                      }
                    });
                  }
                }
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
      )):(
        <IonRow style={{height: "100%"}} class="ion-justify-content-center ion-align-items-center">
          <div>
            <IonSpinner />
          </div>
        </IonRow>
      )}
			</IonContent>
		</IonPage>
	)
}

export default ReportChallenge;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
`

const Question = styled.div`
  font-size: 20px;
`;