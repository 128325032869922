import { IonPage, IonContent, IonRange, IonCol, IonRow, IonSegment, IonSegmentButton, IonLabel, IonItem, IonPopover, IonInput, IonRefresher, IonRefresherContent, IonText, IonIcon } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import PageTitle from "../components/PageTitle";
import SearchResults from "../components/SearchResults";
import SportsDropdown from "../components/SportsDropdown";
import { Users } from "../services/api";
import { RefresherEventDetail } from '@ionic/core';
import useStateCallback from "../hooks/useStateCallback";
import useAuth from "../hooks/useAuth";
import useUser from "hooks/useUser";
import { RouteComponentProps } from "react-router";
import { usePosition } from "hooks/usePosition";
import useDynamicInputs from "hooks/useDynamicInputs";
import GenderCheckboxes from "components/GenderCheckboxes";
import styled from "styled-components";
import { caretDown, caretUp } from "ionicons/icons";
import { FilterContext } from "State/FilterContext";
import CommonHeader from "components/CommonHeader";

const LeaderboardPage: React.FC<RouteComponentProps> = ({history}) => {
	const {token} = useAuth();
	const {user} = useUser();
	const {genders, gendersStringsToObjects} = useDynamicInputs();
	const [time, setTime] = useState<LeaderBoardTime>("all-time");
	const [distance, setDistance] = useState<number>(200);
	const [location, setLocation] = useState<string>("");
	const timeoutID = useRef<number>();
	const [zip, setZip] = useStateCallback<string>(user?.preference?.primary_address?.postal_code ?? "");
	const [selectedSports, setSelectedSports] = useState<string[]>([]);
	const [popoverState, setShowPopover] = useState(false);
	const [results, setResults] = useState<any>([]);
	const [selectedGenders, setSelectedGenders] = useState<string[]>([]);
	const {position, prompt} = usePosition();
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [age, setAge] = useState<AgeRange>({
		lower: 18,
		upper: 65
	});
	const {state, dispatch} = useContext(FilterContext);

	const checkOrigin = async (filters: any) => {
		console.log('==checkOrigin()');
		if (
				// origin not set as location string
				(filters['origin'] === null || filters['origin'] === '') &&
				// position has been aquired 
				(position?.coords?.latitude && position?.coords?.longitude)
		){
			console.log('using browser location as origin');
			const { latitude, longitude } = position.coords;
			filters['origin'] = {
				lat: latitude,
				lon: longitude
			}
		} else if (filters['origin'] !== "" && typeof filters['origin'] === 'string') {
			console.log({origin: filters['origin']});
		} else {
			console.log('prompting', filters);
			let newPosition = await prompt();
			console.log('newPosition', newPosition);
			if (newPosition !== null){
				console.log('setting new position', newPosition);
				filters['origin'] = newPosition.coords;
			} else {
				console.log('no location');
			}
		}
		return filters;
	}

	const loadLeaderboard = async (event: CustomEvent<RefresherEventDetail> | null) => {
		let filters: LeaderBoardFilters = {
			time,
			distance,
			genders: gendersStringsToObjects(selectedGenders),
			ageRange: age,
			origin: location,
			sport: 'all'
		}
		console.log('base filters', filters);
		if (selectedSports.length > 0) {
			filters['sport'] = (typeof selectedSports === "object") ? selectedSports.toString():selectedSports;
		}
		filters = await checkOrigin(filters);

		console.log(filters);
		let response = Users.getLeaders(token ?? "", filters);
		if (response){
			response.then(function(data){
				if (data.success){
					setResults(data.payload);
				} else {
					console.error('failed search', data.message);
				}
				if (event){
					event.detail.complete();
				}
			});
		}
	}

	const updateDistance = (e: any) => {
		setDistance(e.target.value);
	}

	const updateAge = (e: any) => {
		setAge(e.target.value);
	}

	const updateGenderSelection = (checked: boolean, gender_change: string) => {
		const newGenderArr: string[] = selectedGenders;
		if (checked){
			if (newGenderArr.indexOf(gender_change) < 0){
			newGenderArr.push(gender_change);
			}
		} else {
			const index = newGenderArr.indexOf(gender_change);
			if (index > -1) {
			newGenderArr.splice(index, 1);
			}
		}
		setSelectedGenders(newGenderArr);
  }

	// load leader board with default values
	useEffect(() => {
		loadLeaderboard(null);
	}, [user, token, selectedSports, time, origin])

	// setup polling interval to detect change to distance
	useEffect(() => {
		if (timeoutID.current){
			clearTimeout(timeoutID.current);
		}
		timeoutID.current = window.setTimeout(function(){
			loadLeaderboard(null);
		}, 500);
		return () => clearTimeout(timeoutID.current);
	}, [distance]);

	useEffect(() => {
		setSelectedGenders(() => {
			if (genders){
				if (genders && genders.map !== undefined){
					return genders.map(each => each.name ?? "");
				} else {
					return [];
				}
			} else {
				return [];
			}
		})
	}, [genders])

	const show_time_filters = false;
	const use_zip_popup = false;

	return (
		<IonPage>
			<CommonHeader title={`Leaderboard`}/>
			<IonContent fullscreen>
				<div>
					<FiltersSection>
						<FiltersHead onClick={() => setShowFilters(!showFilters)}>
							<FiltersTitle>Filters</FiltersTitle>
							<IonIcon slot="end" icon={(showFilters) ? caretUp:caretDown} />
						</FiltersHead>
						<FiltersBody show={showFilters}>
							{
								(show_time_filters) ? (
									<div style={{ margin: "0 var(--horizontal-gutter)" }}>
										<IonSegment
											onIonChange={e => setTime(e.detail.value as LeaderBoardTime)}
											value={time}
											mode="ios"
										>
											<IonSegmentButton value="all-time">
												<IonLabel>All Time</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="this-month">
												<IonLabel>This Month</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="this-week">
												<IonLabel>This Week</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
								):null
							}
							<SportsDropdown 
								onChange={(e) => {

									setSelectedSports(e);
								}}
								selectedSports={selectedSports}
								prepend={[{name: "All", value: ''}]}
							/>
							<IonItem>
								<IonLabel position="stacked">Location</IonLabel>
								<IonInput
									type="text"
									onIonChange={(e) => {
										let newLocation = e.detail.value;
										setLocation((typeof newLocation === 'string') ? newLocation:"");
									}}
									name="text"
									placeholder="City, State, or Zip Code"
									value={location}
								/>
							</IonItem>
							<IonCol style={{padding: "var(--horizontal-gutter)"}}>
								{
									(use_zip_popup) ? (
										<IonRow style={{padding: "var(--horizontal-gutter)"}} className="ion-justify-content-end">
												<strong>{distance}</strong>
												<span style={{margin: '0 5px'}}>miles from</span>
													<>
														<IonPopover
															isOpen={popoverState}
															onDidDismiss={() => {
																setShowPopover(false)
																loadLeaderboard(null);
															}}
														>
															<IonItem lines="none" >
																<IonLabel position="stacked">Zip Code</IonLabel>
																<IonInput name="zip" onIonChange={(e) => setZip(e.detail.value as string, () => {})} placeholder="Enter Zip Code"/>
															</IonItem>
														</IonPopover>
														<div className="zip-popover" onClick={(e) => {setShowPopover(true)}}>
															<strong><u>{(zip) ? zip:"Zip Code"}</u></strong>
														</div>
													</>
										</IonRow>
									):null
								}
								<IonItem>
									<RowLabel position="stacked">
										<IonText>Distance</IonText>
										<LabelValue>({distance} miles)</LabelValue>
									</RowLabel>
									<IonRange pin={true} dualKnobs={false} min={0} max={300} name="distance" value={distance} onIonChange={updateDistance}>
										<IonText slot="start">0</IonText>
										<IonText slot="end">300</IonText>
									</IonRange>
								</IonItem>
								<IonItem>
									<RowLabel position="stacked">
										<IonText>Age</IonText>
										<LabelValue>{(age && age.lower && age.upper) ? `(${age.lower} - ${age.upper})`:null}</LabelValue>
									</RowLabel>
									<IonRange pin={true} dualKnobs={true} min={18} max={100} name="age" value={age} onIonChange={updateAge}>
										<IonText slot="start">18</IonText>
										<IonText slot="end">100</IonText>
									</IonRange>
								</IonItem>
								<GenderCheckboxes selectedGenders={selectedGenders} onChange={updateGenderSelection}/>
							</IonCol>
						</FiltersBody>
					</FiltersSection>
				</div>
				<IonRefresher slot="fixed" onIonRefresh={loadLeaderboard}>
					<IonRefresherContent>
					</IonRefresherContent>
				</IonRefresher>
				<SearchResults results={results} filters={{
					time,
					distance,
					genders: selectedGenders,
					ageRange: age,
					origin,
					sport: (selectedSports.length > 0) ? selectedSports[0]:null,
				}} searchType="leaderboard" />
			</IonContent>
		</IonPage>
	)
}

export default LeaderboardPage;

const FiltersSection = styled.div`
	
`;

const FiltersHead = styled(IonItem)`
	justify-content: space-between;
`;

const FiltersTitle = styled.div`
	font-size: 20px;
`;

const FiltersBody = styled.div<{show: boolean}>`
	display: ${props => (props.show) ? "block":"none"}
`;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	width: 100%;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;