import { IonContent, IonPage } from "@ionic/react";
import ChallengePrompt from "components/ChallengePrompt";
import ChallengePromptButton from "components/ChallengePromptButton";
import ChallengesTabs from "components/ChallengesTabs";
import CommonHeader from "components/CommonHeader";
import NextChallenge from "components/NextChallenge";
import useChallenges from "hooks/useChallenges";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ChallengesPage: React.FC = () => {
	const {nextChallenge, upcoming, past, pending, refreshChallenges, refreshPast, refreshPending, refreshUpcoming } = useChallenges();
	const [challengeTab, setChallengeTab] = useState<ChallengeListType>('pending');
	const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

	const runRefresh = () => {
		if (challengeTab === 'past'){
			refreshPast(true);
		} else if (challengeTab === 'pending'){
			refreshPending(true);
		} else {
			refreshChallenges(true);
		}
		// always refresh upcoming to handle refreshing next challenge as well
		refreshUpcoming(true);
	}

	useEffect(() => {
		console.log('starting challenges page polling');
		intervalRef.current = setInterval(() => {
			runRefresh();
		}, 5000);
		return () => {
			if (intervalRef.current){
				console.log('stopping challenges page polling');
				clearInterval(intervalRef.current);
			}
		}
	}, [
		refreshChallenges,
		refreshPast,
		refreshPending,
		refreshUpcoming,
		nextChallenge
	]);
	
	return (
		<IonPage>
			<CommonHeader title={`Challenges`}/>
			<IonContent>
				<div style={{ paddingTop: '10px'}}>
					<ChallengePromptButton />
				</div>
				{(nextChallenge?.length && nextChallenge.length > 0) ? (
				<div>
					<SectionTitle>Next Challenge</SectionTitle>
					<NextChallenge
						nextChallenge={nextChallenge}
						refreshChallenges={refreshChallenges}
					/>
				</div>
				):<div style={{height: "10px"}}></div>}
				{(nextChallenge?.length && nextChallenge.length > 0) ? (
					<SectionTitle>All Challenges</SectionTitle>
				):null}
				<ChallengesTabs
					refreshChallenges={refreshChallenges}
					onChallengeTypeChange={(newTab) => {
						refreshChallenges();
						setChallengeTab(newTab)
					}}
					upcoming={upcoming}
					past={past}
					pending={pending}
				/>
			</IonContent>
		</IonPage>
	)
}

export default ChallengesPage;

const SectionTitle = styled.div`
	padding: var(--horizontal-gutter);
	font-size: 25px;
	font-family: var(--heading-font);
`;