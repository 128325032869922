import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { ReactNode, useContext, useState } from 'react';
import { Search } from '../services/api';
import useAuth from '../hooks/useAuth';
import { RouteComponentProps } from 'react-router';
import SearchResults from '../components/SearchResults';
import { arrowBack, person } from 'ionicons/icons';
import styled from 'styled-components';
import { usePosition } from 'hooks/usePosition';
import LoadingPage from './LoadingPage';
import { FilterContext } from '../State/FilterContext';
import useUser from 'hooks/useUser';
import UpdateAddressPrompt from 'components/UpdateAddressPrompt';
import { useAsyncEffect } from 'use-async-effect';
import NoResults from 'components/NoResults';

interface SearchArgs {
	filters: (MatchSearch | TournamentSearch),
	type: SearchType
}

const ResultsPage: React.FC<RouteComponentProps<{}, any, LocationProps | any>> = () => {
	const [results, setResults] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string>("");
	const [originDisplay, setOriginDisplay] = useState<string | ReactNode | null>(null);
	const {position, prompt} = usePosition();
	const {token} = useAuth();
	const {user, getUser, userLoading} = useUser();
	const {state, dispatch} = useContext(FilterContext);

	/*
		returns true if process should continue,
		returns false to indicate waiting for 
	*/
	const checkOrigin = async (filters: any) => {
		console.log('==checkOrigin()');
		if (
				// origin null or an empty string
				(filters['origin'] === null || filters['origin'] === '') &&
				// position has already been aquired
				(position?.coords?.latitude && position?.coords?.longitude)
		){
			const { latitude, longitude } = position.coords;
			let coords = {
				lat: latitude,
				lon: longitude
			}
			if (state.searchType === 'match'){
				dispatch({type: "match_setOrigin", payload: coords})
			} else if (state.searchType === 'tournament') {
				dispatch({type: "tournament_setOrigin", payload: coords});
			}
			filters['origin'] = coords;
		} else if (filters['origin'] === null || filters['origin'] === '') {
			console.log('prompting', filters);
			let newPosition = await prompt();
			console.log('newPosition', newPosition);
			if (newPosition !== null){
				console.log('setting new position', newPosition);
				if (state.searchType === 'match'){
					dispatch({type: "match_setOrigin", payload: newPosition.coords})
				} else if (state.searchType === 'tournament') {
					dispatch({type: "tournament_setOrigin", payload: newPosition.coords});
				}
				filters['origin'] = newPosition.coords;
			}
		}
		return filters;
	}

	const getFilters = async () => {
		console.log('==getFilters()');
		let ready_filters;
		let search_type;

		// get filters from browser state or
		// fallback to defaults
		if ((state.searchType === 'match' && state.matchFilters) || (state.searchType === 'tournament' && state.tournamentFilters)){
			if (state.searchType === 'match'){
				ready_filters = state.matchFilters;
				search_type = 'match';
			} else {
				ready_filters = state.tournamentFilters;
				search_type = 'tournament';
			}
		} else {
			/* ESTABLISH DEFAULTS HERE FOR FALLBACK */
			console.log('falling back to defaults');
			const default_search_filters = {
				"sports": [],
				"origin": null,
				"distance": 500,
				"ageRange": null,
				"ranked": false,
				"genders": []
			}
			const default_search_type = 'match';
			ready_filters = default_search_filters;
			search_type = default_search_type;
		}

		console.log("filters before origin check", ready_filters);
		ready_filters = await checkOrigin(ready_filters);
		console.log("filters after origin check", ready_filters);

		let args: SearchArgs = {
			filters: ready_filters,
			type: search_type as SearchType
		}

		return args;
	}

	const search = async (event: CustomEvent<RefresherEventDetail> | null, args: SearchArgs) => {
		setIsLoading(true);
		setError("");
		let results = [];
		if (args.filters !== undefined){
			let response;
			if (token){
				if (state.searchType === 'match'){
					response = await Search.match(token || "", state.matchFilters);
				} else if (state.searchType === 'tournament'){
					response = await Search.tournament(token || "", state.tournamentFilters);
				}
				console.log(response);
				if (response){
					if (response.success){
						results = response.payload;
					} else {
						console.error('failed search', response.message);
						console.log(response);
						console.log(response.message);
					}
					if (event){
						event.detail.complete();
					}
				}
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
			setError("Problem Searching");
		}
		return results;
	}

	const updateOrigin = async (args: SearchArgs, user: User) => {
		let originDisplay = null;
		let sure_user = user;
		if (!user && getUser){
			console.log('recognized no user, but has getUser');
			sure_user = await getUser();
		}
		if (args?.filters?.origin){
			console.log('getOriginDisplay from origin', args.filters.origin);
			if (typeof args.filters.origin !== 'string' && (args.filters.origin.lat || args.filters.origin.lon)){
				console.log('origin has lat & lon', args.filters);
				let lat = (args.filters.origin.lat) ? args.filters.origin.lat.toFixed(2):"lat?";
				let lon = (args.filters.origin.lon) ? args.filters.origin.lon.toFixed(2):"lon?";
				originDisplay = `${lat}, ${lon}`;
			} else if (typeof args.filters.origin === 'string') {
				console.log('problem here?', args.filters.origin);
				originDisplay = args.filters.origin;
			}
		} else if (sure_user?.preference?.location) {
			console.log('getOriginDisplay from user primary location: ', sure_user.preference.location);
			originDisplay = sure_user.preference.location;
		} else {
			console.log('getOriginDisplay from none: Show update profile prompt', sure_user);
			originDisplay = (
				<UpdateAddressPrompt
					msg={
						<IonCol style={{
							textAlign: "left",
							display: "flex",
							flexDirection: 'column',
							padding: '15px 10px',
							margin: '0 auto'
						}}>
							<IonText style={{fontWeight: 'bold', paddingBottom: '5px'}}>Doing so will</IonText>
							<IonText>-Allow you to search without turning on location</IonText>
							<IonText>-Allow you to show up in searches</IonText>
						</IonCol>
					}
					buttons={[
						<IonButton
							key="edit-button"
							color="accent-two"
							expand="block" 
							routerLink="/user/profile/edit"
						>
							<IonIcon icon={person} style={{marginRight: '5px'}}></IonIcon>
							<IonText>Edit Profile</IonText>
						</IonButton>
					]} 
				/>
			);
		}

		setOriginDisplay(originDisplay);
	}

	useAsyncEffect(async () => {
		if (!userLoading && user){
			console.log('START');
			const args = await getFilters();
			console.log('FILTER ARGS', args);
			await updateOrigin(args, user);
			console.log('performing search with', args);
			const results = await search(null, args);
			console.log('search results', results);
			setResults(results);
		}
	}, [
		token,
		user,
		userLoading,
		user?.preference,
		user?.preference?.location,
	])

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton
							routerLink={(() => {
								if (state.searchType){
									return `/search/${state.searchType}`;
								} else {
									return `/search/match`;
								}
							})()}
						>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
					</IonButtons>
					<IonTitle color="main-text">
						Search Results
						<Subtitle>{state.searchType && (() => {
							let string = state.searchType;
							return string[0].toUpperCase() + string.substring(1);
						})()}</Subtitle>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{(!isLoading && !error) ? (
					<div>
						<IonRefresher slot="fixed" onIonRefresh={async (e) => {
							if (!userLoading && user){
								const args = await getFilters();
								await updateOrigin(args, user);
								const results = await search(null, args);
								setResults(results);
							}
						}}>
							<IonRefresherContent>
							</IonRefresherContent>
						</IonRefresher>
						<Origin>{originDisplay}</Origin>
						{(!results || results.length <= 0) ? <NoResults link={(() => {
						if (state.searchType){
							return `/search/${state.searchType}`;
						} else {
							return `/search/match`;
						}
					})()}/>:null}
						<SearchResults
							results={results}
							searchType={state.searchType}
							filters={(state.searchType === 'match') ? state.matchFilters:state.tournamentFilters}
						/>
					</div>
				):(
						(isLoading) ? <LoadingPage />:(
							<SearchError>
								<IonText>{error}</IonText>
								<IonRow className="ion-justify-content-center" style={{padding: '10px'}}>
									<IonButton 
										color="main"
										routerLink={(() => {
											if (state.searchType){
												return `/search/${state.searchType}`;
											} else {
												return `/search/match`;
											}
										})()}
									>
										<IonText>Try Again</IonText>
									</IonButton>
								</IonRow>
							</SearchError>
						)
				)}
			</IonContent>
		</IonPage>
	);
};

export default ResultsPage;

const Subtitle = styled(IonText)`
	font-size: 12px;
	display: block;
`

const SearchError = styled.div`
	text-align: center;
	padding: 20px var(--horizontal-gutter);
	background: var(--ion-color-danger);
	color: var(--constant-light);
`;

const Origin = styled.div`
	text-align: center;
	padding: var(--horizontal-gutter);
	color: var(--ion-color-main-text);
	font-size: 12px;
`;