import {
	IonPage,
	IonContent,
	IonCardTitle,
} from "@ionic/react";
import TitleText from "../../components/TitleText";
import TitleCard from "../../components/TitleCard";
import useAsyncEffect from "use-async-effect";
import { Users } from "../../services/api";
import { getPlatforms } from '@ionic/react';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Waves from "components/Waves";
import TitleCardSimple from "components/TitleCardSimple";

const AboutPage = () => {

	useAsyncEffect(async () => {
		let result = await Users.getInfo();
	},[])

	return (
		<IonPage>
			<IonContent className="ion-padding basic-bg basic-bg-pad content-width">
				<div style={{ padding: "50px 0 30px"}}>
					<TitleCardSimple />
				</div>
				<div>
					...contact info
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AboutPage