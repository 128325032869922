import { IonPage, IonContent, IonButton, useIonAlert, IonItem, IonLabel, IonProgressBar, IonText } from "@ionic/react"
import CommonHeader from "components/CommonHeader"
import PageTitle from "components/PageTitle";
import PasswordInput from "components/PasswordInput";
import Toast from "components/Toast";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import { useState } from "react";
import { Users } from "services/api";
import { AuthenticationService } from "services/auth";
import styled from "styled-components";

const AccountSettingsPage: React.FC = () => {
	const {token, userID} = useAuth();
	const [present] = useIonAlert();
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [strengthText, setStrengthText] = useState('');
	const [strengthNum, setStrengthNum] = useState(0);
	const [toast] = useToast();
	
	const deleteAccount = async () => {
		if (userID){
			const response = await Users.delete(token ?? "", userID);
			if (response){
				AuthenticationService.logout();
			}
			console.log("DELETE USER RESPONSE", response);
		}
	}

	const getPassed = (password: string): number => {
		var passed = 0;
		if (password.length >= 8){
			passed++;
		}

		//Regular Expressions.
		var regex = new Array();
		regex.push("[A-Z]"); //Uppercase Alphabet.
		regex.push("[a-z]"); //Lowercase Alphabet.
		regex.push("[0-9]"); //Digit.
		regex.push("[$@$!%*#?&]"); //Special Character.

		//Validate for each Regular Expression.
		for (var i = 0; i < regex.length; i++) {
				if (new RegExp(regex[i]).test(password)) {
						passed++;
				}
		}
		switch (passed) {
			case 0:
				setStrengthText("Very Weak");
				break;
			case 1:
				setStrengthText("Weak");
				break;
			case 2:
				setStrengthText("Okay");
				break;
			case 3:
				setStrengthText("Good");
				break;
			case 4:
				setStrengthText("Strong");
				break;
			case 5:
				setStrengthText("Very Strong");
				break;
		}

		setStrengthNum(passed);

		return passed;
	}

	const showLabels = false;

	return (
		<IonPage>
			<CommonHeader title="Account Settings" defaultHref="/user/profile/view" logout={true} />
			<IonContent className="ion-padding light-bg">
				<ContentInner>
					<SafeZone>
						<div className="basic-form dark">
							<ActionTitle>Update Password</ActionTitle>
							<IonItem>
								{showLabels && <IonLabel position="stacked">Password</IonLabel>}
								<PasswordInput
									onChange={setPassword}
									onInput={(e: any) => {
										getPassed(e.target.value);
										setPassword(e.target.value);
									}}
									dark={true}
									placeholder="Password"
									password={password}
									name="password"
								/>
							</IonItem>
							<IonItem>
								{showLabels && <IonLabel position="stacked">Confirm Password</IonLabel>}
								<PasswordInput 
									onChange={setConfirm}
									password={confirm}
									dark={true}
									placeholder="Confirm Password"
									name="confirm"
								/>
							</IonItem>
							<IonButton
								expand="block"
								color="main"
								style={{marginTop: '20px'}}
								onClick={async () => {

									if (password === ""){
										toast({message: "Please enter a password"}, true);
										return false;
									}
		
									if (password.length > 20 || password.length < 8){
										console.log({password});
										toast({message: "Password must be between 8 and 20 characters"}, true);
										return false;
									}
		
									let passed = getPassed(password);
		
									if (passed < 5){
										toast({
											message: "Password must include\n-  1 Uppercase Letter\n-  1 Lowercases Letter\n-  1 Number (0-9)\n-  1 Special Character ($@$!%*#?&)",
											duration: 0
										}, true);
										return false;
									}
		
									if (password !== confirm){
										toast({message: "Passwords must match"}, true);
										return false;
									}

									const args = {
										password,
										confirm
									}

									try {
										let result = await Users.updatePassword(token ?? "", args);
										if (result && result.success){
											toast({message: "Update Successful"});
										} else {
											toast({message: "Update Failed"}, true);
										}
									} catch (error){
										toast({message: "Update Failed"}, true);
									}
								}}
							>
								Save
							</IonButton>
						</div>
						{(strengthText) ? (
						<StyledProgressBarContainer>
							<div style={{padding: "7px 0", fontSize: '12px'}}>
								<IonText>Password Strength: </IonText><IonText>{strengthText}</IonText>
							</div>
							<IonProgressBar 
								value={strengthNum / 5}
							/>
						</StyledProgressBarContainer>
						):null}
					</SafeZone>
					<DangerZone>
						<DangerZoneHeader>
							<DangerZoneTitle>Danger Zone</DangerZoneTitle>
							<DangerZoneMessage>
								Careful in this section. This is where you make account changes that cannot be undone.
								Be sure you know what you are doing.
							</DangerZoneMessage>
						</DangerZoneHeader>
						<DangerZoneContent>
							{/*
								DELETE ACCOUNT
							*/}
							<IonButton
								expand="block"
								color="danger"
								onClick={() => {
									present({
										header: 'Are you sure?',
										message: 'Do you want to delete your account? This action cannot be undone',
										buttons: [
											{ text: 'Cancel', handler: () => console.log('cancelled') },
											{ text: 'Delete', handler: deleteAccount }
										]
									});
								}}
								style={{ margin: 14 }}
							>
								DELETE ACCOUNT
							</IonButton>
						</DangerZoneContent>
					</DangerZone>
				</ContentInner>
			</IonContent>
		</IonPage>
	)
}

export default AccountSettingsPage;

const ContentInner = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

const SafeZone = styled.div``;

const DangerZone = styled.div`
	margin-top: 50px;
	padding: 20px;
	border-radius: 15px;
	border: 5px solid var(--ion-color-danger);
`;

const DangerZoneHeader = styled.div`
	padding-bottom: 50px;
`;

const DangerZoneTitle = styled.h1`
	font-family: var(--heading-font);
`;

const DangerZoneMessage = styled.div`
	font-size: 13px;
`;

const DangerZoneContent = styled.div`

`;

const ActionTitle = styled.div`
	font-size: 20px;
	font-family: var(--heading-font);
`;

const Message = styled.div`
	margin-right: 10px;
	color: white;
`;

const StyledProgressBarContainer = styled.div`
	margin-top: 15px;
`;