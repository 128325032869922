import { IonCol } from "@ionic/react";
import useAuth from "hooks/useAuth";
import useChallenges from "hooks/useChallenges";
import useUser from "hooks/useUser";
import { ReactNode, useEffect, useState } from "react";
import { Challenges } from "services/api";
import { generateRandomString } from "services/util";
import Card from "./Card";
import Challenge from "./Challenge";
import ChallengePrompt from "./ChallengePrompt";

const NextChallenge: React.FC<{
	nextChallenge: Challenge[],
	refreshChallenges: () => void
}> = ({nextChallenge, refreshChallenges}) => {

  const { user, userLoading } = useUser();
  const [nextChallengeNodes, setNextChallengeNodes] = useState<ReactNode[]>();
	const [challengesCache, setChallengesCache] = useState<Challenge[]>([]);

  const renderNextChallenge = (user: User): ReactNode[] => {
		if (nextChallenge && nextChallenge.map !== undefined){
			return nextChallenge.map((challenge) => {
				if (challenge){
					let color = 'accent-two';
					let textColor = 'main';
					let boxType = 'solid';
					let passProps = {
						challenge,
						boxType: boxType as FillType,
						dotColor: color as ColorType,
						boxColor: color as ColorType,
						boxTextColor: textColor as ColorType,
						contextual_user_id: user.id,
						issuer: challenge.issuer,
						recipients: challenge.recipients,
						isContextUserIssuer: challenge.issuer.id === user?.id,
						allowViewChallenge: true
					}
					return (
						<Challenge key={generateRandomString()} refreshChallenges={refreshChallenges} {...passProps} />
					)
				} else {
					console.log({ user, id: user?.id });
					return [];
				}
			})
		} else {
			return [];
		}
	}


	useEffect(() => {
		if (!userLoading){
			if (
				user &&
				(
					challengesCache.length === 0 ||
					JSON.stringify(nextChallenge) !== JSON.stringify(challengesCache)
				)
			){
				console.log('not skipped');
				setChallengesCache(nextChallenge);
				let newNodes = renderNextChallenge(user);
				setNextChallengeNodes(newNodes);
			} else {
				console.log('skipped');
			}
		}
	}, [nextChallenge, user, userLoading])

  return (
		<Card>
			<IonCol>
				{ nextChallengeNodes }
			</IonCol>
		</Card>
	);
}

export default NextChallenge;