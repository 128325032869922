import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, IonItem, IonList, IonListHeader, IonModal, IonPage, IonRadioGroup, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react"
import ProfileImage from "components/ProfileImage";
import useAuth from "hooks/useAuth";
import useToast from "hooks/useToast";
import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { Challenges, Tournaments } from "services/api";
import useAsyncEffect from "use-async-effect";
import styled from 'styled-components';
import { generateRandomString } from "services/util";
import CenteredSpinner from "components/CenteredSpinner";
import { timeAgo, toAppDateFormat, toAppFormat, toAppTimeFormat } from "../services/time";
import IonTextTip from "components/IonTextTip";
import useTournaments from "hooks/useTournaments";
import ReportTournament from "components/ReportTournament";

interface Ratings {
  [id: string]: number
}

interface TournamentPageProps extends RouteComponentProps<{
	tournament_view_id?: string;
}> {}

const TournamentPage: React.FC<TournamentPageProps> = ({ match }) => {
  const { tournament_view_id } = match.params;
	const {token, userID} = useAuth();
  const [toast] = useToast("");
  const {refreshTournaments} = useTournaments();
  const [participants, setParticipants] = useState<User[]>([]);
  const [tournament, setTournament] = useState<Tournament>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);

  // form state
  const [winner, setWinner] = useState<string>();
  const [players, setPlayers] = useState<ReactNode[]>([]);

  /**
   * 
   * @param users array of users who participanted in the game
   * @param issuerId the user id of the tournament issuer
   * @returns A react component displaying the users
   */
  const getPlayers = ((users: User[], organizer_id: string) => {
    return (users) ? users.map((user, index) => {

      // check if we have the user info we need
      if (user && user.id && user.preference){

        // establisih some tag defaults
        let colors = {
          textColor: 'constant-dark',
          color: 'success'
        }

        // array of tags we'll display
        let tags = [];

        // tags for if the tournament is complete
        if (tournament && tournament?.status === 'Complete' && tournament.reported_winner_id !== null){
          if (user.id === tournament.reported_winner_id){
            // adding the winner tag
            colors = {
              textColor: 'constant-light',
              color: 'success'
            }
            tags.push(<Tag key={`winner-${userID}`} color={colors.color} textColor={colors.textColor}>Winner</Tag>);
          } else {
            // we'll skip adding a loser tag for now
          }
        } else {
          // if it's any other status
          // show user invitation status
          if (user.invitation_status == "Accepted"){
            // if they accepted and the status is reporting, show their reporting instead that its been accepted
            if (tournament?.status === 'Reporting'){
              //tags.push(<Tag key={`reporting-${userID}`} color="main" textColor="constant-light">Participated</Tag>)
            } else {
              if (tournament){
                if (user.id.toString() === tournament.user_id.toString()){
                  tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="main" textColor="constant-light">Organizer</Tag>); 
                } else {
                  tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);    
                }
              }
             }
          } else if (user.invitation_status == "Pending"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          } else if (user.invitation_status == "Dismissed"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          } else if (user.invitation_status == "Complete"){
            colors = {
              textColor: 'constant-dark',
              color: 'success'
            }
            tags.push(<Tag key={`${user.invitation_status}-${userID}`} color="success" textColor="constant-dark">{user.invitation_status}</Tag>);
          }
        }

        return (
          <IonItem key={generateRandomString()} lines="none" routerLink={`/user/view/${user.id}`} routerDirection="forward">
            <Player 
              className="ion-align-items-center" 
              key={index}
              isSelected={user.id == winner}
            >
              <ProfileImage
                icon_type="none" 
                icon_color="primary"
                size="75px" 
                filename={user.preference.image}
              />
              <IonCol>
                <IonText>{user.short_name}</IonText>
								<IonRow style={{fontSize: "12px", paddingTop: "3px"}}>
									{user.preference.location}
								</IonRow>
                <IonRow>
                  {tags}
                </IonRow>
              </IonCol>
            </Player>
          </IonItem>
        )
      }
    }):[]
  });

  /**
   * Get's the status pill for the header
   * @param status
   * @returns void
   */
  const getStatusElement = (status: string | undefined) => {
    if (status === 'Dismissed' || status === 'Cancelled'){
      return <EventTag color="danger" textColor="constant-light" slot="end">{status}</EventTag>
    } else if (status === 'Complete'){
      return <EventTag color="success" textColor="main-text" slot="end">{status}</EventTag>
    } else {
      return <EventTag color="main" textColor="constant-light" slot="end">{status}</EventTag>
    }
  }

  const getActions = (status: string | undefined) => {
    const Wrapper: React.FC = ({children}) => (
      <Actions>{children}</Actions>
    );
		let found_user = tournament?.users.find(user => user.id === userID);
    let user_status = found_user && found_user?._joinData?.status;
    if (status === 'Upcoming'){

			// is not organizer
      if (tournament?.user_id !== userID){
				// is not already joined, Join Button
				if (
					tournament && 
					(!tournament.users || !tournament.users.find(u => u.id === userID)) &&
          parseInt(tournament.openSlots) > 0
				){
					return (
						<Wrapper>
							<IonButton
								size="default"
								color="main"
								style={{flex: 1}}
								onClick={ async () => {
									let response = await Tournaments.join(token ?? "", String(tournament?.id));
									if (response && response.success){
										if (tournament && tournament.id !== undefined){
											getData(tournament.id.toString());
											toast({ message: response.message[0] });
										}
									} else if (response && !response.success) {
										toast({ message: response.message[0] }, true);
									}
								}}
							>
								Join
							</IonButton>
						</Wrapper>
					);
        // has already joined, Leave Button
				} else if (
          tournament &&
					(tournament.users && tournament.users.find(u => u.id === userID))
        ){
          
					return (
						<Wrapper>
							<IonButton
								size="default"
								color="danger"
								style={{flex: 1}}
								onClick={ async () => {
									let response = await Tournaments.leave(token ?? "", String(tournament?.id));
									if (response && response.success){
										if (tournament && tournament.id !== undefined){
											getData(tournament.id.toString());
											toast({ message: response.message[0] });
										}
									} else if (response && !response.success) {
										toast({ message: response.message[0] }, true);
									}
								}}
							>
								Leave
							</IonButton>
              {(user_status === 'Pending') ? (
                <IonButton
                  size="default"
                  color="success"
                  style={{flex: 1}}
                  onClick={ async () => {
                    let response = await Tournaments.updateStatus(token ?? "", tournament?.id, "accepted");
                    console.log('confirm response', response);
                    if (response && response.success){
                      if (tournament && tournament.id !== undefined){
                        getData(tournament.id.toString());
                        toast({ message: response.message[0] });
                      }
                    } else if (response && !response.success){
                      toast({ message: response.message[0] }, true);
                    }
                  }}
                >
                  Confirm
                </IonButton>
              ):null}
						</Wrapper>
					);
				}

      // is organizer
      } else {
        return (
          <Wrapper>
              <IonButton
                size="default"
                color="danger"
                style={{flex: 1}}
                onClick={async () => {
                  await Tournaments.updateStatus(token ?? "", tournament?.id, "cancelled");
                  setTimeout(refreshTournaments, 1000);
                }}
              >
                Cancel
              </IonButton>
              {(tournament && !tournament.frozen) ? (
              <IonButton
                size="default"
                color="main"
                style={{flex: 1}}
                routerLink={`/tournament/edit/${tournament?.id}`}
              >
                Modify
              </IonButton>
              ):null}
          </Wrapper>
        );
      }
    } else if (status === 'Reporting'){
      const isOrganizer = tournament?.user_id === userID;
			if (isOrganizer){
				return (
					<Wrapper>
						<IonButton
							size="default"
							color="main"
							style={{flex: 1}}
							onClick={() => {
								setModalOpen(true);
							}}
						>
							Report
						</IonButton>
					</Wrapper>
				);
			}
    }
  }

	const getData = async (id: string) => {
    if (!token) return;
		const result = await Tournaments.view(token ?? "", id);
		if (result.success){
			setIsLoading(false);
			const { users, user_id } = result.payload;
      console.log('TOURNAMENT', result.payload);
			const participants = users.filter((user: User) => user.id !== userID);
			let ratings: Ratings = {};
			participants.forEach((user: User) => {
				ratings[user.id] = 5;
			});
			setTournament(result.payload);
			setParticipants(participants);
			setPlayers(getPlayers(users, user_id));
		} else {
      console.log('fail', result);
      console.log('redirect');
      toast({message: "Tournament does not exist"}, true);
      //history.push('/');
    }
		return Challenges.viewCancel;
	}

  useAsyncEffect(async () => {
		if (tournament_view_id){
    	return getData(tournament_view_id);
		}
  }, [token, userID, tournament_view_id])

  useEffect(() => {
    if (tournament){
      setPlayers(getPlayers(tournament.users, tournament.user_id));
      Tournaments.setTournamentViewed(token ?? "", tournament.id);
    }
  },[tournament]);

	return (
		<IonPage>
			<IonHeader>
			<IonToolbar>
			  <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle color="main-text">Tournament</IonTitle>
        {getStatusElement(tournament?.status)}
			</IonToolbar>
		</IonHeader>
			<IonContent>
        {(!isLoading) ? (
			  <IonList>
          {(tournament) ? 
          (<>
						<TournamentTitle>{tournament.title}</TournamentTitle>
            <StartsIn>{timeAgo(tournament.tournament_start).agoString}</StartsIn>
						<IonCard>
							<IonListHeader>
								<CardHead>Details</CardHead>
							</IonListHeader>
              <DetailsHeader>
							<IonItem routerLink={`/user/view/${tournament.issuer.id}` } routerDirection="forward" lines="none">
								<div style={{paddingTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: "1"}}>
									<ProfileImage
										icon_type="none" 
										icon_color="primary"
										size="90px"
										filename={tournament.issuer.preference.image ?? ""}
										style={{margin: "15px 0", padding: "0", minWidth: "90px"}}
									/>
									<DetailLabel style={{marginLeft: 'auto', marginRight: "0", padding: "0 0 0 25px"}}>
										<div style={{paddingBottom: "10px"}}>
                      <div><IonText style={{fontSize: "13px"}}>Organizer:</IonText></div>
                      <div><IonText style={{fontSize: "18px"}}>{tournament.issuer.short_name}</IonText></div>
                    </div>
										<DetailSubLabel>
											<DetailLabelText>Created:</DetailLabelText>
											<IonTextTip
                        title={toAppFormat(tournament.created)}
                      >
                        {timeAgo(tournament.created).agoString}
                      </IonTextTip>
										</DetailSubLabel>
										<DetailSubLabel>
											<DetailLabelText>Modified:</DetailLabelText>
											<IonTextTip
                        title={toAppFormat(tournament.modified)}
                      >
                        {timeAgo(tournament.modified).agoString}
                      </IonTextTip>
										</DetailSubLabel>
									</DetailLabel>
								</div>
							</IonItem>
              </DetailsHeader>
              <DetailRow lines="none">
                <DetailLabel>Start Date</DetailLabel>
                <DetailValueColumn>
                  <DetailValue>{toAppDateFormat(tournament.tournament_start)}</DetailValue>
                </DetailValueColumn>
              </DetailRow>
              <DetailRow lines="none">
                <DetailLabel>Start Time</DetailLabel>
                <DetailValueColumn>
                  <DetailValue>{toAppTimeFormat(tournament.tournament_start)}</DetailValue>
                </DetailValueColumn>
              </DetailRow>
              <DetailRow lines="none">
                <DetailLabel>End Date</DetailLabel>
                <DetailValueColumn>
                  <DetailValue>{toAppDateFormat(tournament.tournament_end)}</DetailValue>
                </DetailValueColumn>
              </DetailRow>
              <DetailRow lines="none">
                <DetailLabel>End Time</DetailLabel>
                <DetailValueColumn>
                  <DetailValue>{toAppTimeFormat(tournament.tournament_end)}</DetailValue>
                </DetailValueColumn>
              </DetailRow>
							<IonItem lines="none">
								<DetailLabel>Sport</DetailLabel>
								<IonText>{tournament.sport?.name}</IonText>
							</IonItem>
							<IonItem lines="none">
								<DetailLabel>Location</DetailLabel>
								<IonText>{tournament.location}</IonText>
							</IonItem>
							<IonItem lines="none">
								<DetailLabel>Total Slots</DetailLabel>
								<IonText>{tournament.slots}</IonText>
							</IonItem>
						</IonCard>
					</>
          ):null}
					<IonCard>
            <IonListHeader>
              <CardHead>Description</CardHead>
            </IonListHeader>
            <IonList>
              <IonItem lines="none">{tournament?.message}</IonItem>
              {tournament?.notes?.map((note: Note) => (
                <IonItem lines="none" key={generateRandomString()}>
                  <Note slot={(note.user_id === tournament.user_id) ? "start":"end"}>
                    <NoteMsg>{note.message}</NoteMsg>
                    <NoteDetails>
                      <NoteAuthor>{note?.user.short_name}</NoteAuthor>
                      <NoteTime>{toAppFormat(note?.created)}</NoteTime>
                    </NoteDetails>
                  </Note>
                </IonItem>
              ))}
            </IonList>
          </IonCard>
          <IonCard>
            <IonListHeader>
              <CardHead>
								<IonRow className="ion-justify-content-between ion-align-items-center" style={{paddingRight: '16px'}}>
									<IonText>Participants</IonText>
									{(tournament) ? (<IonText style={{fontSize: "12px"}}>{tournament.openSlots} Open Slots</IonText>):null}
								</IonRow>
							</CardHead>
            </IonListHeader>
            <IonRadioGroup 
              value={winner} 
              onIonChange={e => {
                setWinner(e.detail.value)
              }}
              name="winner"
            >
              {(tournament?.users && tournament?.user_id) ? getPlayers(tournament?.users ?? [], tournament?.user_id):null}
            </IonRadioGroup>
          </IonCard>
          
          {getActions(tournament?.status)}
          { (tournament) ? (
              <IonModal isOpen={modalOpen}>
                <ReportTournament onCloseClick={() => setModalOpen(false)} tournament={tournament} />
              </IonModal>
            ):null
          }
        </IonList>
        ):<CenteredSpinner />}
			</IonContent>
		</IonPage>
	)
}

export default TournamentPage;

const Player = styled.div<{isSelected: boolean}>`
  display: flex;
  padding: 0 0 10px 0;
  &:first-of-type {
    padding-top: 5px;
  }
`

const CardHead = styled.div`
	flex: 1;
  font-size: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--horizontal-gutter);
`;

const Tag = styled.div<{color: string, textColor: string}>`
  background: ${props => `var(--ion-color-${props.color})`};
  color: ${props => `var(--ion-color-${props.textColor})`};
  padding: 2px 15px;
  border-radius: 100px;
  text-align: center;
  width: min-content;
  font-size: 13px;
  margin: 5px 10px 0 0;
`;

const EventTag = styled.div<{color: string, textColor: string}>`
  background: ${props => `var(--ion-color-${props.color})`};
  color: ${props => `var(--ion-color-${props.textColor})`} !important;
  padding: 7px 15px;
  border-radius: 100px;
  text-align: center;
  width: min-content;
  margin-right: 25px;
`;

const DetailLabel = styled.div`
  font-size: 20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DetailLabelText = styled(IonText)`
`;

const DetailSubLabel = styled.div`
  font-size: 12px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between; 
  & > ${DetailLabelText} {
    margin-right: 10px;
  }
	@media (max-width: 275px){
		flex-direction: column;
		padding-bottom: 5px;
	}
`;

const Note = styled(IonCol)`
  background: #fff;
  border-radius: 5px;
  color: #333;
  width: 90%;
`;

const NoteMsg = styled.div`
  padding: 10px 10px 5px;
`;

const NoteDetails = styled(IonRow)`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 5px;
  margin-top: 5px;
  padding: 3px 10px;
  background: #ddd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: max-content;
`;

const NoteAuthor = styled.div`
  font-size: 12px;
`;

const NoteTime = styled.div`
  font-size: 10px;
`;

const TournamentTitle = styled.h1`
	padding: 0 20px 0px;
	text-align: center;
	color: var(--ion-color-main-text);
`;

const StartsIn = styled.div`
  padding: 0 20px 10px;
  font-size: 12px;
  text-align: center;
  color: var(--ion-color-main-text);
`;

const DetailRow = styled(IonItem)`
  align-items: center;
  --padding-top: 0;
  --padding-bottom: 0;
`;

const DetailValueColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailValue = styled.div`
  text-align: right;
  padding-left: 20px;
`;


const DetailsHeader = styled.div`
  background: var(--ion-background-color);
  padding: 0px 16px;
  color: var(--ion-item-color, var(--ion-text-color, #000));
`;