import { IonCheckbox, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup, IonSelect, IonSelectOption } from "@ionic/react";
import React, { ReactNode, useEffect, useState } from "react";
import useDynamicInputs from "../hooks/useDynamicInputs";
import { generateRandomString } from "../services/util";

interface GenderRadioProps {
  selectedGender: string
  onChange(gender: string): void
}

interface OptionProps {
  options: ReactNode[]
}

const GenderRadioOptions: React.FC<OptionProps> = ({options}) => {
  return (
    <div>
      {options}
    </div>
  )
}
const MemoizedOptions = React.memo(GenderRadioOptions);

const GenderRadioInner: React.FC<GenderRadioProps & OptionProps> = ({selectedGender, onChange, options}) => {
  return (
    <IonRadioGroup 
      value={selectedGender} 
      onIonChange={e => onChange(e.detail.value)}
      name="gender"
    >
      <IonListHeader>
        <IonLabel>Gender</IonLabel>
      </IonListHeader>
      <MemoizedOptions options={options} />
    </IonRadioGroup>
  )
}

const GenderRadio: React.FC<GenderRadioProps> = (props) => {
  const {genders} = useDynamicInputs();
  const [options, setOptions] = useState<ReactNode[]>([]);

  const getGenderRadios = () => {
    let options: React.ReactNode[] = [];
    if (genders && genders.map !== undefined && genders.length > 0){
      options = genders.map((gender: Gender) => (
        <IonItem key={generateRandomString()}>
          <IonLabel>{gender.name}</IonLabel>
          <IonRadio slot="end" value={gender.name} />
        </IonItem>
      ));
    }
    return options;
  }

  useEffect(() => {
    const options = getGenderRadios();
    setOptions(options);
  }, [genders])

  return (
    <IonList>
      <GenderRadioInner {...props} options={options}/>
    </IonList>
  );
}

export default GenderRadio;