
import { CONFIG } from "../constants";
import axios from "axios";
import { makeGetRequest, makePostRequest } from './fetching'

const TOKEN_ERROR = {
	success: false, 
	message: ["No token supplied"]
};

const makeAxioPost = (url: string, token: string, formData: FormData): Promise<any> => {
	let config = {
    headers: { 
			"Authorization": `Bearer ${token}`,
			"Accept": 'application/json',
			"Content-Type": "multipart/form-data"
		}
  }
	return axios.post(url, formData, config)
}

export const Users = {
	getCurrentUser: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/self';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getCurrentUserCancel = controller.abort;
		return result;
	},
	getCurrentUserCancel: () => {},

	getUsers: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getUsersCancel = controller.abort;
		return result;
	},
	getUsersCancel: () => {},

	getUser: async (token: string, userID: string | null) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/view/' + userID;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getUserCancel = controller.abort;
		return result;
	},
	getUserCancel: () => {},

	updateProfileImage: async (token: string, formData: FormData) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-profile-image';
		const result = await makeAxioPost(endpoint, token, formData);
		return result;
	},

	updateProfile: async (token: string, data: ProfileUpdatePayload) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-profile';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal);
		return result;
	},

	getInfo: async () => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/info';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal);
		Users.getInfoCancel = controller.abort;
		return result;
	},
	getInfoCancel: () => {},

	getLeaders: async (token: string, args: LeaderBoardFilters) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/leaders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.getLeadersCancel = controller.abort;
		return result;
	},
	getLeadersCancel: () => {},

	updatePassword: async (token: string, args: any) => {
		const endpoint = CONFIG.API_ENDPOINT + '/users/update-password';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, args, signal, true);
		Users.updatePasswordCancel = controller.abort;
		return result;
	},
	updatePasswordCancel: () => {},

	getPrefersDark: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/prefers-dark';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.getPrefersDarkCancel = controller.abort;
		return result;
	},
	getPrefersDarkCancel: () => {},

	updateThemePreference: async (token: string, prefersDark?: boolean) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/users/update-theme-preference';
		if (prefersDark != undefined){
			endpoint += '/' + prefersDark;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.updateThemePreferenceCancel = controller.abort;
		return result;
	},
	updateThemePreferenceCancel: () => {},

	requestVerificationEmail: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/send-verification-email';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Users.requestVerificationEmailCancel = controller.abort;
		return result;
	},
	requestVerificationEmailCancel: () => {},

	search: async (token: string, term: string, selected_emails: string[]) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/users/search?term='+term;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, selected_emails, signal, true);
		Users.searchCancel = controller.abort;
		return result;
	},
	searchCancel: () => {},

	delete: async (token: string, user_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/users/delete/' + user_id
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	}
}

export const Addresses = {
	add: async (token: string, data: Address) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/addresses/add';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, data, signal, true);
		return result;
	},
	update: async (token: string, address: Address) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/addresses/update/' + address.id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makePostRequest(endpoint, token, address, signal, true);
		return result;
	},
	delete: async (token: string, address: Address) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/addresses/delete/' + address.id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	}
}

export const Challenges = {

	view: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/view';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.viewCancel = controller.abort;
		return result;
	},
	viewCancel: () => {},

	edit: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/edit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = makePostRequest(endpoint, token, args, signal, true);
		Challenges.editCancel = controller.abort;
		return result;
	},
	editCancel: () => {},

	getPending: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/pending';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getPendingCancel = controller.abort;
		return result;
	},
	getPendingCancel: () => {},

	getPast: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/past';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getPastCancel = controller.abort;
		return result;
	},
	getPastCancel: () => {},

	getUpcoming: async (token: string, id: string | null) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/upcoming';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.getUpcomingCancel = controller.abort;
		return result;
	},
	getUpcomingCancel: () => {},

	getNumModified: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/challenges/getNumModified';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	issue: async (token: string, args: any) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/issue';
		const controller = new AbortController();
		const signal = controller.signal;
		return makePostRequest(endpoint, token, args, signal, true);
	},

	updateStatus: async (token: string, id: string | undefined, status: InvitationStatus) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/updateStatus/' + id + '/' + status;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.updateStatusCancel = controller.abort;
		return result;
	},
	updateStatusCancel: () => {},

	setChallengeViewed: async (token: string, challenge_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/challenges/setViewed/' + challenge_id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	cancel: async (token: string, id: string | undefined) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/cancel/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Challenges.cancelCancel = controller.abort;
		return result;
	},
	cancelCancel: () => {},

	report: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/challenges/report/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		Challenges.reportCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal, true);
	},
	reportCancel: () => {}, 
}

export const DynamicInputs = {
	getSports: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/sports';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getSportsCancel = controller.abort;
		return result;
	},
	getSportsCancel: () => {},

	getGenders: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/genders';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getGendersCancel = controller.abort;
		return result;
	},
	getGendersCancel: () => {},

	getUserAddresses: async (token: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/users/addresses';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		DynamicInputs.getUsersAddressesCancel = controller.abort;
		return result;
	},
	getUsersAddressesCancel: () => {}
}

export const Search = {
	match: async (token: string, args: MatchSearch): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/users';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.matchCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	matchCancel: () => {},

	tournament: async (token: string, args: TournamentSearch): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments';
		const controller = new AbortController();
		const signal = controller.signal;
		Search.matchCancel = controller.abort;
		return makePostRequest(endpoint, token, args, signal);
	},
	tournamentCancel: () => {}
}

export const Tournaments = {

	list: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/user';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.listCancel = controller.abort;
		return result;
	},
	listCancel: () => {},

	organizing: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/organizing';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.organizingCancel = controller.abort;
		return result;
	},
	organizingCancel: () => {},

	create: async (token: string, args: any): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/create';
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.createCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	createCancel: () => {},

	report: async (token: string, args: any, tournament_id: string): Promise<any> => {
		if (!token) return false;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/report/' + tournament_id;
		const controller = new AbortController();
		const signal = controller.signal;
		Tournaments.reportCancel = controller.abort;
		const result = makePostRequest(endpoint, token, args, signal, true);
		return result;
	},
	reportCancel: () => {},

	edit: async (token: string, args: any, id: string) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/edit/' + id;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = makePostRequest(endpoint, token, args, signal, true);
		Tournaments.editCancel = controller.abort;
		return result;
	},
	editCancel: () => {},

	getNumModified: async (token: string) => {
		if (!token) return TOKEN_ERROR;
		let endpoint = CONFIG.API_ENDPOINT + '/tournaments/getNumModified';
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	view: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/view';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.viewCancel = controller.abort;
		return result;
	},
	viewCancel: () => {},

	setTournamentViewed: async (token: string, tournament_id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/setViewed/' + tournament_id;
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		return result;
	},

	join: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/join';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.joinCancel = controller.abort;
		return result;
	},
	joinCancel: () => {},

	leave: async (token: string, id: string) => {
		if (!token) return TOKEN_ERROR;
		let url = CONFIG.API_ENDPOINT + '/tournaments/leave';
		if (id){
			url += '/' + id;
		}
		const endpoint = url;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.joinCancel = controller.abort;
		return result;
	},
	leaveCancel: () => {},

	updateStatus: async (token: string, id: string | undefined, status: InvitationStatus) => {
		if (!token) return TOKEN_ERROR;
		const endpoint = CONFIG.API_ENDPOINT + '/tournaments/updateStatus/' + id + '/' + status;
		const controller = new AbortController();
		const signal = controller.signal;
		const result = await makeGetRequest(endpoint, signal, token);
		Tournaments.updateStatusCancel = controller.abort;
		return result;
	},
	updateStatusCancel: () => {},
}