const makeGetRequest = async (url:string, signal: AbortSignal | null, token?: string) => {
	if (signal === null){
		const controller = new AbortController();
		signal = controller.signal;
	}
	let response = await fetch(url, {
		method: "GET",
		headers: {
			'Authorization': `Bearer ${token}`,
			'Accept': 'application/json'
		},
		signal
	}).then(function(response){
		if (response.ok) {
			return response;
		}
		console.log('FETCHING RESPONSE', response);
		throw new Error('Something went wrong');
	})
	.catch(function(error){
		console.log(error);
	})
	return await response?.json();
}

const makePostRequest = async (
	url:string,
	token: string,
	data: any,
	signal: AbortSignal | null,
	json = true,
) => {
	const requestHeaders: HeadersInit = new Headers();
	if (token){
		requestHeaders.set("Authorization", `Bearer ${token}`);
	}
	requestHeaders.set("Accept", 'application/json');
	if (json){
		data = JSON.stringify(data);
		requestHeaders.set("Content-Type", 'application/json');
	}
	let response = await fetch(url, {
	method: "POST",
		headers: requestHeaders,
		body: data,
		signal
	})
	.then(function(response){
		if (response.ok) {
			return response;
		}
		console.log('FETCHING RESPONSE', response);
		throw new Error('Something went wrong');
	})
	.catch(function(error){
		console.log(error);
	});

	return await response?.json();
}

export {
	makeGetRequest,
	makePostRequest,
};