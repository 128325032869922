import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { Challenges } from "../services/api";
import useAuth from "./useAuth";

function useChallenges(id?: string){
	const {token, userID} = useAuth();
	const [pending, setPending] = useState<Challenge[]>([]);
  const [past, setPast] = useState<Challenge[]>([]);
	const [upcoming, setUpcoming] = useState<Challenge[]>([]);
	const [nextChallenge, setNextChallenge] = useState<Challenge[]>([]);

	const logIfShould = (...params: any[]) => {
		const shouldLog = false;
		if (shouldLog){
			console.log(...params);
		}
	}

	useAsyncEffect(async () => {
		await setAllStates();
		return () => {
			Challenges.getPendingCancel();
			Challenges.getPastCancel();
			Challenges.getUpcomingCancel();
		}
	}, [token, id, userID])

	const getID = () => {
		let id_to_use: string | null = null;
		if (!id){
			id_to_use = userID;
		} else {
			id_to_use = id;
		}
		return id_to_use
	}

	const updatePending = async (id: string | null, check = false) => {
		let pending_res = await Challenges.getPending(token ?? "", id);
		if (pending_res && pending_res.success){
			if (!check || JSON.stringify(pending_res.payload) !== JSON.stringify(pending)){
				logIfShould('updating pending challenges');
				setPending([...pending_res.payload]);
			} else {
				logIfShould('skipping updating pending challenges');
			}
		}
	}

	const updatePast = async (id: string | null, check = false) => {
		let past_res = await Challenges.getPast(token ?? "", id);
		if (past_res && past_res.success){
			if (!check || JSON.stringify(past_res.payload) !== JSON.stringify(past)){
				logIfShould('updating past challenges');
				setPast([...past_res.payload]);
			} else {
				logIfShould('skipping updating past challenges');
			}
		}
	}

	const updateUpcoming = async (id: string | null, check = false) => {
		let upcoming_res = await Challenges.getUpcoming(token ?? "", id);
		if (upcoming_res && upcoming_res.success){
			if (!check || JSON.stringify(upcoming_res.payload) !== JSON.stringify(upcoming)){
				logIfShould('updating upcoming challenges');
				if (upcoming_res.payload.length > 0){
					setNextChallenge([upcoming_res.payload[0]]);
				} else { 
					setNextChallenge([]);
				}
				setUpcoming([...upcoming_res.payload]);
			} else {
				logIfShould('skipping updating upcoming challenges');
			}
		}
	}

	const setAllStates = async (check = false) => {
		if (token){
			let id = getID();
			if (id !== null){
				updatePending(id, check);
				updatePast(id, check);
				updateUpcoming(id, check);
			}
		}
	}

	const setPendingState = async (check = false) => {
		if (token){
			let id = getID();
			if (id !== null){
				updatePending(id, check);
			}
		}
	}

	const setPastState = async (check = false) => {
		if (token){
			let id = getID();
			if (id !== null){
				updatePast(id, check);
			}
		}
	}

	const setUpcomingState = async (check = false) => {
		if (token){
			let id = getID();
			if (id !== null){
				updateUpcoming(id, check);
			}
		}
	}

	const getRefreshFunction = () => {
		return setAllStates;
	}

	const getRefreshPending = () => {
		return setPendingState;
	}

	const getRefreshPast = () => {
		return setPastState;
	}

	const getRefreshUpcoming = () => {
		return setUpcomingState;
	}

	const ChallengesPayload = {
		pending,
		past,
		upcoming,
		nextChallenge,
		refreshChallenges: getRefreshFunction(),
		refreshPending: getRefreshPending(),
		refreshPast: getRefreshPast(),
		refreshUpcoming: getRefreshUpcoming()
	};
	
	return ChallengesPayload;
}

export default useChallenges;