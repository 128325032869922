import { STATES } from "../constants";
import SearchDropdown from "./SearchDropdown";

interface Option {
	value: string,
	text: string
}

interface StateSelectProps {
	initialValue: string
	onChange: (option: Option) => void
}
const StateSelect: React.FC<StateSelectProps> = ({ onChange, initialValue }) => {

	const stateOptions = Object.keys(STATES).map(key => {
		return({value: key, text: STATES[key]});
	})

	const findInitialOption = () => {
		let initialOption = stateOptions.find(op => op.value === initialValue);
		if (initialOption){
			return initialOption;
		}
		return null;
	}
	
	return (
		<SearchDropdown
			placeholder="Select a State"
			options={stateOptions}
			onChange={onChange}
			initialOption={findInitialOption()}
			filterBy="or"
		/>
	);
}

export default StateSelect;