import { IonButton, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import CommonHeader from "components/CommonHeader";
import PillRadios from "components/PillRadios";
import UsersSelect from "components/UsersSelect";
import useAuth from "hooks/useAuth";
import useDynamicInputs from "hooks/useDynamicInputs";
import useToast from "hooks/useToast";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useParams } from "react-router";
import { Tournaments } from "services/api";
import { getDateISO, joinISODateAndTime } from "services/time";
import styled from "styled-components";
import useAsyncEffect from "use-async-effect";

const ModifyTournamentPage: React.FC = () => {
  let { id } = useParams<Params>();
	const {user} = useUser();
  const {token} = useAuth();
  const {sports} = useDynamicInputs();
	const [title, setTitle] = useState<string>("");
  const [sport, setSport] = useState<string>(user && user.sports[0]?.name || "");
  const [tournament_start_date, setStartDate] = useState<string>("");
  const [tournament_start_time, setStartTime] = useState<string>("");
  const [tournament_end_date, setEndDate] = useState<string>("");
  const [tournament_end_time, setEndTime] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [open_invite, setOpenInvite] = useState<boolean>(false);
	const [slots, setSlots] = useState<string>("3");
  const [toast] = useToast("Hello");

	const getMaxAsNumber = () => {
		if (!isNaN(parseInt(slots))){
			return parseInt(slots);
		} else {
			return null;
		}
	}

  useAsyncEffect(async () => {
    if (id){
      const result = await Tournaments.view(token ?? "", id);
      if (result.success){
        console.log('result.payload', result.payload);
        const { message, sport, tournament_start, tournament_end, location, title, slots, open_invite } = result.payload;
        setSport(sport.name);
        setTitle(title);
        setStartDate(tournament_start);
        setStartTime(tournament_start);
        setEndDate(tournament_end);
        setEndTime(tournament_end);
        setLocation(location);
        setOpenInvite(open_invite);
        setSlots(slots);
        setMessage(message);
      }
    }
  }, [token])
  
  return (
		<IonPage>
      <CommonHeader title={`Modify Challenge`}/>
			<IonContent>
			<IonList>
          <IonItem className="form-input" data-name="sport">
            <IonLabel>Sport</IonLabel>
            <PillRadios
              options={(sports.map((each: Sport) => each.name)) ?? []}
              value={sport}
              onChange={(selectedSport:string) => setSport(selectedSport)}
            />
          </IonItem>
					<IonItem className="form-input" data-name="title">
            <IonLabel position="stacked">Title</IonLabel>
            <IonInput
              name="title"
              type="text"
              placeholder="Tournament Title"
              value={title}
              onIonChange={(e) => {
                  setTitle(e.detail.value as string);
              }}
            />
          </IonItem>
          <TimeRow>
            <IonItem className="form-input" data-name="tournament_start_date">
              <IonLabel position="stacked">Start Date</IonLabel>
              <IonDatetime
                name="tournament_start_date"
                displayFormat="MMM DD YYYY"
                placeholder="Select Start Date"
                value={tournament_start_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setStartDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="tournament_start_time">
                <IonLabel position="stacked">Start Time</IonLabel>
                <IonDatetime
                  name="tournament_start_time"
                  displayFormat="h:mm A"
                  placeholder="Select Start Time"
                  value={tournament_start_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setStartTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <TimeRow>
            <IonItem className="form-input" data-name="tournament_end_date">
              <IonLabel position="stacked">End Date</IonLabel>
              <IonDatetime
                name="tournament_end_date"
                displayFormat="MMM DD YYYY"
                placeholder="Select End Date"
                value={tournament_end_date}
                onIonChange={e => {
                  let localDate = e.detail.value!;
                  console.log({localDate});
                  setEndDate(localDate);
                }}
                max={(() => {
                  let d = new Date();
                  let year = d.getFullYear();
                  let next_year = year + 1;
                  return String(next_year);
                })()}
              />
            </IonItem>
            <IonItem className="form-input" data-name="tournament_end_time">
                <IonLabel position="stacked">End Time</IonLabel>
                <IonDatetime
                  name="tournament_end_time"
                  displayFormat="h:mm A"
                  placeholder="Select End Time"
                  value={tournament_end_time}
                  onIonChange={e => {
                    let localTime = e.detail.value!;
                    console.log({localTime});
                    setEndTime(localTime);
                  }}
                  ></IonDatetime>
            </IonItem>
          </TimeRow>
          <IonItem className="form-input" data-name="location">
            <IonLabel position="stacked">Location</IonLabel>
            <IonInput
              name="location"
              type="text"
              placeholder="City, State, or Zip Code"
              value={location}
              onIonChange={(e) => {
                  setLocation(e.detail.value as string);
              }}
            />
          </IonItem>
          <IonItem>
              <IonLabel>Open Invite</IonLabel>
              <IonToggle checked={open_invite} onIonChange={e => setOpenInvite(e.detail.checked)} />
          </IonItem>
					<IonItem>
						<IonLabel>Slots</IonLabel>
						<IonInput
							slot="end"
							name="slots"
							type="number"
							value={slots}
							onIonChange={(e) => {
								setSlots(e.detail.value as string);
							}}
              style={{textAlign: 'right', marginRight: '25px'}}
						></IonInput>
					</IonItem>
          <IonItem className="form-input" data-name="message">
            <IonLabel position="stacked">Message</IonLabel>
            <IonTextarea
              name="message"
              placeholder="Message"
              value={message}
              auto-grow={false}
              rows={6}
              spellCheck={true}
              onIonChange={e => setMessage(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
          <IonButton
            color="main"
            expand="block"
            style={{ margin: 14 }}
            onClick={(e) => {
              if (id){
                if (!e.currentTarget) return;
                e.preventDefault();

                if (!sport){
                  toast({message: 'Select a sport'}, true);
                  return;
                }

                if (!title){
                  toast({message: 'Set a title'}, true);
                  return;
                }

                if (!location){
                  toast({message: 'Add a location'}, true);
                  return;
                }


                if (!(tournament_start_date && tournament_start_time)){
                  toast({message: 'Start data & time is required'}, true);
                  return;
                }
                const tournament_start = joinISODateAndTime(tournament_start_date, tournament_start_time);
                
                if (!(tournament_end_date && tournament_end_time)){
                  toast({message: 'End data & time is required'}, true);
                  return;
                }
                const tournament_end = joinISODateAndTime(tournament_end_date, tournament_end_time);

                let args = {
                  title,
                  open_invite,
                  sport,
                  location,
                  tournament_start,
                  tournament_end,
                  message,
                  slots
                }
                console.log('tournament create args', args);
                let response = Tournaments.edit(token ?? "", args, id);
                if (response){
                  response.then(function(data){
                    if (data && data.success){
                      toast({message: "Tournament Updated"});
                    } else {
                      toast({message: "Problem Updating Tournament"}, true);
                    }
                  })
                }
              } else {
                toast({message: "Problem Updating Tournament"}, true);
              }
            }}
          >
            Submit
          </IonButton>
        </IonList>
			</IonContent>
    </IonPage>
  );
}

export default ModifyTournamentPage;

const TimeRow = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;