import { IonList, IonItem, IonLabel, IonInput, IonRange, IonText, IonButton, IonDatetime, IonModal } from "@ionic/react";
import TournamentCreate from "pages/TournamentCreate";
import { useState, useContext } from "react";
import SportsDropdown from "./SportsDropdown";
import styled from 'styled-components';
import { FilterContext } from '../State/FilterContext';

const TournamentSearch: React.FC = () => {
	const {state, dispatch} = useContext(FilterContext);
	const [modalOpen, setModalOpen] = useState(false);

	// event handler for new sports selectiong
	const setSelectedSports = (selectedSports: string[]) => {
		if (JSON.stringify(selectedSports) !== JSON.stringify(state.matchFilters.sports)){
			dispatch({type: "tournament_setSelectedSports", payload: selectedSports});
		}
	}

	return (
		<IonList lines="none">
			<IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				onClick={(e) => {
					if (!e.currentTarget) {
						return;
					}
					e.preventDefault();
					setModalOpen(true);
				}}
			>
				Create Tournament
			</IonButton>
			<IonModal isOpen={modalOpen}>
				<TournamentCreate onCloseClick={() => setModalOpen(false)} />
			</IonModal>
			<SportsDropdown
				multiple={true}
				onChange={setSelectedSports}
				selectedSports={state.tournamentFilters.sports}/>
			<IonItem>
				<IonLabel position="stacked">Location</IonLabel>
				<IonInput
					type="email"
					onIonChange={(e) => {
						dispatch({type: 'tournament_setOrigin', payload: e.detail.value})
					}}
					name="text"
					placeholder="City, State, or Zip Code"
					value={(typeof state.tournamentFilters.origin === 'string') ? state.tournamentFilters.origin:""}
				/>
			</IonItem>
			<IonItem>
				<RowLabel position="floating">
					<IonText>Distance</IonText>
					<LabelValue>({state.tournamentFilters.distance} miles)</LabelValue>
				</RowLabel>
				<IonRange
					pin={true}
					dualKnobs={false}
					min={0}
					max={300}
					name="distance"
					value={state.tournamentFilters.distance}
					onIonChange={(e) => {
						dispatch({type: 'tournament_setDistance', payload: e.detail.value})
					}}
				>
					<IonText slot="start">0</IonText>
					<IonText slot="end">300</IonText>
				</IonRange>
			</IonItem>
			<IonItem>
				<IonLabel>Start Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					placeholder="Select Date"
					value={state.tournamentFilters.startDate}
					onIonChange={e => {
						dispatch({type: 'tournament_setStartDate', payload: e.detail.value})
					}}
				></IonDatetime>
			</IonItem>
			<IonItem>
				<IonLabel>End Date</IonLabel>
				<IonDatetime
					displayFormat="MM DD YY"
					placeholder="Select Date"
					value={state.tournamentFilters.endDate}
					onIonChange={e => {
						dispatch({type: 'tournament_setEndDate', payload: e.detail.value})
					}}
				></IonDatetime>
			</IonItem>
			<IonButton
				color="main"
				expand="block"
				style={{ margin: 14 }}
				routerLink="/results"
				onClick={() => {
					dispatch({type: 'setSearchType', payload: 'tournament'});
				}}
			>
				Search
			</IonButton>
		</IonList>
	)
}

export default TournamentSearch;

const RowLabel = styled(IonLabel)`
	display: flex !important;
	justify-content: space-between;
	width: 100%;
`;

const LabelValue = styled(IonText)`
	margin-left: 10px;
	font-size: 12px;
`;