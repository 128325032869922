import { useRef, useState } from "react";
import {
	IonItem,
	IonLabel,
	IonPage,
	IonContent,
	IonRow
} from "@ionic/react";
import { AuthenticationService } from '../../services/auth';
import TextLink from "../../components/TextLink";
import PasswordInput from "../../components/Primitives/PasswordInput";
import useToast from "../../hooks/useToast";
import TitleCardSimple from "components/TitleCardSimple";
import AuthNav from "components/AuthNav";
import styled from "styled-components";
import Button from "components/Primitives/Button";
import Input from "components/Primitives/Input";

const LoginPage = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [toast] = useToast();
	const emailRef = useRef<HTMLIonInputElement>(null);
	const passwordRef = useRef<HTMLIonInputElement>(null);

	return (
		<IonPage className="ion-align-items-center">
			<IonContent className="ion-padding basic-bg basic-bg-pad content-width">
				<div style={{ padding: "50px 0 30px"}}>
					<TitleCardSimple />
				</div>
				<form className="bubble-form">
					<IonItem lines="none">
						<Input
							ref={emailRef}
							type="email"
							onIonChange={(e: any) => {
								setEmail(e.detail.value as string);
							}}
							autocomplete="email"
							name="email"
							placeholder="Email"
							value={email}
						/>
					</IonItem>
					<IonItem lines="none">
						<PasswordInput
							ref={passwordRef}
							onChange={setPassword}
							password={password}
							placeholder="Password"
							name="password"
							autocomplete="current-password"
						/>
					</IonItem>
				</form>
				<div style={{marginTop: "10px"}}>
					<Button
						color="accent-two"
						expand="block"
						onClick={(e: any) => {
							if (!e.currentTarget) {
								return;
							}
							e.preventDefault();

							console.log({
								emailRef: emailRef?.current?.value,
								passwordRef: passwordRef?.current?.value 
							});

							// get email
							let email_value = email;
							if (emailRef.current && emailRef.current.value){
								email_value = emailRef.current.value as string;
							} 

							// get password
							let password_value = password;
							if (passwordRef.current && passwordRef.current.value){
								password_value = passwordRef.current.value as string;
							}

							console.log({ email_value, password_value });

							let response = AuthenticationService.login(email_value, password_value);
							response.then(response => {
								if (response !== undefined){
									if (response.success){
										toast({message: response.message});
									} else {
										toast({message: response.message}, true);
									}
								}
							})
						}}
					>
						Sign In
					</Button>
				</div>
				<div style={{ padding: 10, paddingTop: 20 }}>
					<IonRow className="ion-justify-content-center ion-justify-content-evenly link-row">
						<TextLink href="/forgot" color="accent-two">Forgot Password?</TextLink>
					</IonRow>
					<Buffer />
					<IonRow className="ion-justify-content-center ion-align-items-center link-row">
						<Message>Don't have an account?</Message>
						<TextLink href="/register" color="accent-two">Sign Up</TextLink>
					</IonRow>
				</div>
				<AuthNav />
			</IonContent>
		</IonPage>
	);
};

export default LoginPage

const Buffer = styled.div`
	min-height: 30px;
`;

const Message = styled.div`
	color: white;
	margin-right: 10px;
`;